

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function createStore () {
  return new Vuex.Store({
    state: {
      org:{title:null,orgId:null},
      userManagerState: null ,//企业用户认证状态
      searchJob: '', //职位
      storeActivePath: '', //选中导航
      isLogin: false,
      loginUserInfo: null,
      // trtc 相关
      callStatus: '', // 状态, idle, calling, connected
      isInviter: false, // c2c 通话，说不定一开始不是 inviter, 后面邀请了别人就是 inviter 了
      meetingUserIdList: [],
      muteVideoUserIdList: [],
      muteAudioUserIdList: []
    },
    mutations: {
      changeOrgid(state,val){//修改org
        if(val==null){
          state.org.title="广信区"
          state.org.orgId=3
          localStorage.setItem("curorg",null)
        }else{
          state.org.title=val.districtName
          state.org.orgId=val.id
          localStorage.setItem("curorg",JSON.stringify(val))
        }
        
      },
      changeUserManager(state, {userManagerState}) {
        state.userManagerState = userManagerState
      },
      changeSearchJob (state, {searchJob}) {
        state.searchJob = searchJob
      },
      changeActivePath (state, {storeActivePath}) {
        if (!state.storeActivePath) {
          setTimeout(() => {
            state.storeActivePath = storeActivePath
          }, 0)
        } else {
          state.storeActivePath = ''
          setTimeout(() => {
            state.storeActivePath = storeActivePath
          }, 0)
        }
      },
      userLoginSuccess (state) {
        state.isLogin = true;
      },
      userLogoutSuccess (state) {
        state.isLogin = false;
        state.loginUserInfo = null;
      },
      setLoginUserInfo (state, payload) {
        const {userId, userSig} = payload;
        state.loginUserInfo = {
          userId, userSig
        }
      },
      updateIsInviter (state, isInviter) {
        state.isInviter = isInviter;
      },
      updateCallStatus (state, callStatus) {
        state.callStatus = callStatus;
      },
      userJoinMeeting (state, userId) {
        if (state.meetingUserIdList.indexOf(userId) === -1) {
          state.meetingUserIdList.push(userId);
        }
      },
      userLeaveMeeting (state, userId) {
        const index = state.meetingUserIdList.findIndex(item => item === userId);
        if (index >= 0) {
          state.meetingUserIdList.splice(index, 1);
        }
      },
      dissolveMeeting (state) {
        state.meetingUserIdList = [];
        state.isMuteVideoUserIdList = [];
        state.isMuteAudioUserIdList = [];
      },
      updateMuteVideoUserIdList (state, userIdList) {
        state.muteVideoUserIdList = userIdList;
      },
      updateMuteAudioUserIdList (state, userIdList) {
        state.muteAudioUserIdList = userIdList;
      }
    }
  });
}

const store = createStore();
export default store;



