/*
* 用户端的路由白名单
*
* */

const userList = [
    '/user/index',
    '/user/searchJobs',
    '/user/companyList',
    '/user/companyNews',
    '/user/companyNews/gongsi',
    '/user/companyNews/cJobs',
    '/user/jobDetail',
    '/user/u_blank',
    '/user/jobFair',
    '/user/jobFair-desc',
    '/user/notice/articleDetails',
    '/user/public/register',
    '/user/jobList',
    '/user/onlineJobFair',
    '/user/onlineJobFairDetails',
    '/user/news',
    '/user/show',
    '/school/job',
    '/user/jkq',
    '/user/changeweb',
    '/school/result',
    '/school/news',
    '/school/show',
    '/school/zhidao',
    '/school/showzd',
    '/user/tempIndex',
    '/user/newJobList',
    '/user/companyJobs',
    '/tempCompanyList',
    '/shareEmp',

]

const userTokenList = [  // 用户端需要token进入的页面
    '/user/resumes',
    '/user/realname',
    '/user/settingInfo/userInfo',
    '/user/settingInfo/editUserPassword',
    '/user/creatResume',
    '/user/videohome',
    '/user/video-call',
    '/user/sendlist',
    '/user/sendshow',
    '/school/yuyue',
    '/user/smartjob',
    '/user/delivery',
    '/user/deliveryRecord',
    '/user/interview',
    '/user/tempInterview',
]

export default {userList,userTokenList}
