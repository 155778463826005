<template>
    <div>
        <el-dialog
        :title="title"
        :visible.sync="isshow"
        :width="width"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <slot name="box"></slot>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button v-if="haveconfirm" type="primary" @click="okconfirm" class="mybotton2">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    props:{
        isshow:{
            type:Boolean,
            default:false
        },
        haveconfirm:{
            type:Boolean,
            default:true
        },
        title:{
            type:String
        },
        width:{
            type:String
        }
    },
    methods:{
        handleClose(){
            this.$emit("close",false)
        },
        okconfirm(){
            this.$emit("confirm",true)
        }
    }
}
</script>
