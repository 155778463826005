
const API={
    QueryBrandSort:"/brand/QueryBrandSort",//
    login:"/memLogin/login",//登录post
    sendCode:"/sms/sendCode",//企业用户注册发送验证码get
    checkCode:"/sms/checkCode",//企业用户注册校对验证码post
    register:"/memLogin/register",//企业用户注册校对验证码post
    logOut:"/memLoginOut/logOut",//企业用户退出登录get
    forgetPassword:"/memLogin/forgetPassword",//修改密码发送验证码post
    sendForGetCode:"/sms/sendForGetCode",//忘记密码修改密码get
    getPermission:"/rolePer/getPermission",//获取会员操作列表get
    addMemberInfo:"/memberInfo/addMemberInfo",//开通会员，添加会员信息post
    addRole:"/role/addRole",//开通角色post
    queryRole:"/role/queryRole",//获取角色列表get
    selectAllPermission:"/rolePer/selectAllPermission",//获取角色权限列表get
    getPermissionByRoleId:"/rolePer/getPermissionByRoleId",//根据角色ID获取角色权限列表get
    deleteRole:"/role/deleteRole",//删除角色get
    addPermission:"/permission/addPermission",//添加、修改权限post
    queryPermission:"/permission/queryPermission",//获取权限菜单get
    selectByPerId:"/permission/selectByPerId",//根据权限ID获取权限信息get
    deletePermission:"/permission/deletePermission",//删除权限get
    queryAdminMember:"/member/queryAdminMember",//查询用户列表post
    lockMember:"/member/lockMember",//改变用户冻结状态post
    queryAdminRole:"/role/queryAdminRole",//查询管理员所有的角色get
    addMember:"/member/addMember",//后台添加管理员post
    deleteMember:"/member/deleteMember",//删除用户get
    selectMemberById:"/member/selectMemberById",//查询用户信息get
    updateMember:"/member/updateMember",//编辑修改用户信息post
    checkAccount:"/member/checkAccount",//添加用户检验用户名是否存在get
    queryCompanyMember:"/member/queryMember",//查询企业用户post
    checkPhone:"/member/checkPhone",//添加企业检查手机号get
    makeInfoOrPassWord:"/member/makeInfoOrPassWord",//管理员个人中心修改个人信息post
    adminQueryUser:"/admin/user/adminQueryUser",//管理员后台获取用户列表post
    amdinLockUser:"/admin/user/lockUser",//锁定用户post
    adminDeleteUser:"/admin/user/deleteUser",//管理员删除用户get
    selectMemberBaseInfoById:"/memberBaseInfo/selectMemberBaseInfoById",//管理员页面企业管理获取企业基本信息详情页get
    queryByMemberId:"/memberAuthInfo/queryByMemberId",//管理员模块查询企业认证信息详情get
    AuthChangeStatus:"/memberAuthInfo/changeStatus",//管理员模块企业认证审核post
    getMemberRecord:"/memberAuthInfo/getMemberRecord",//管理员模块企业认证获取变更记录get
    selectWorkByMemberId:"/work/selectWorkByMemberId",//管理员模块获取职位列表post
    queryMemberNotPage:"/member/queryMemberNotPage",//职位管理获取企业列表get
    deleteWorkById:"/work/deleteWorkById",//管理员模块删除职位get
    WorkChangeStatus:"/work/changeStatus",//管理员模块审核职位post
    changeActionWork:"/work/changeActionWork",//管理员模块改变职位开启状态post
    changeUserType:"/admin/user/changeUserType",//管理员模块改变用户类型post
    addLegal:"/legal/addLegal",//管理员模块添加招聘负责人post
    checkLegal:"/legal/checkLegal",//管理员模块添加招聘负责人先检查是否存在get
    queryLegal:"/legal/queryLegal",//管理员模块获取招聘负责人列表post
    queryLegalId:"/legal/queryLegalId",//管理员模块获取单个招聘负责人信息get
    deleteLegal:"/legal/deleteLegal",//管理员模块删除招聘负责人get
    makeLegalStatus:"/legal/makeLegalStatus",//管理员模块修改招聘负责人认证状态post
    findLegal:"/legal/findLegal",//管理员查询招聘负责人get
    queryLegalList:"legal/queryLegalList",//管理员企业列表查询招聘负责人条件get
    queryIndustry:"/work/Industry/pcQueryIndustry",//管理员模块获取全部行业列表get
    addIndustry:"/work/Industry/addIndustry",//管理员模块新建行业post
    deleteIndustry:"/work/Industry/deleteIndustry",//管理员模块删除行业get
    changeIndustryIsShow:"/work/Industry/changeIsShow",//管理员模块切换显示状态post
    exportWork: '/work/exportWork',  // 导出职位列表
    screenquery: '/work/queryAll',  
    screenxamine: '/work/examine',  
    screeninsert: '/work/insertCompanyWork',  
    screendel: '/work/del',  
    screenedit: '/work/edit',  
    forward: '/forward',
    industryuse: '/industry/use',
    export: '/forward/export ',
    district: '/district/list',//返回所有县区
    classification: '/classification/insert',//高校新闻分类
    classificationlist: '/classification/list',//分类列表
    classificationedit: '/classification/edit',//分类编辑
    classificationdelete: '/classification/delete',//分类删除
    curriculum: '/curriculum',//就业指导
    curriculumdel: '/curriculum/del',//就业指导
}

export default API