<template>
    <div class="showpicbox">
        <el-popover
            placement="top-start"
            title=""
            :width="width"
            trigger="hover"
           >
            <slot name="content">
                <img :src="url" width="100%"  alt="">
            </slot>
            <el-image
            slot="reference"
            :style="'width: '+wid+'; height:'+wid"
            :src="url"
            fit="cover"></el-image>
        </el-popover>
    </div>
</template>
<script>
export default {
    data(){
        return{
            
        }
    },
    props:{
        width:{
            type:String,
            defautl:"200"
        },
        url:{
            type:String,
        },
        wid:{
            type:String,
            defautl:"60px"
        },
    }
}
</script>
<style scoped>
.itempic{float: left;}
</style>

