<template>
  <!--用户端-->
  <div class="home-user">
    <user-header :currentIndex="currentTab"></user-header>
    <div>
      <router-view @setIndex="setCurrentTab"></router-view>
    </div>
<!--    <user-footer v-if="footerShow"></user-footer>-->
  </div>
</template>
<script>
import UserHeader from "./NewUserHeader.vue";
import UserFooter from "@/components/layout2/NewUserFooter";
import userRequest from "@/api/user";
export default {
  components: {
    UserFooter,
    UserHeader,
  },
  data() {
    return {
      footerShow: true,
      currentTab: 0,
    };
  },
  mounted() {},
  methods: {
    setCurrentTab(index) {
      this.currentTab = index;
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/css/global.scss";
@import "../../assets/css/flexLayout";
.home-user {
  // height: 100vw;
  background: #f4f6f9;
}
.user-content {
  width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  * {
    box-sizing: border-box;
  }
  .home-user {
    height: 100vw;
    background: #f6f6f8;
  }
  .footer-box {
    display: none;
  }
  .back-up {
    width: 100vw;
    height: 80px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .back-up-a {
      text-decoration: none;
      color: black;
      font-size: 20px;
    }
  }
  .user-content {
    width: 100vw;
    margin: 0 auto;
  }
}
</style>
