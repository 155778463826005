<template>
    <div>
        <div class="footer-box" style="height: 100px;" >
            <!-- <div class="user-content">
                <div class="footer-content-box">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="left">
                                <h2 class="text-lg text-bold text-black">广信区人力资源市场</h2>
                                <img src="@/assets/img/newIndex/icon.png" alt="" class="logo" />
                                <div class="flex flex-direction justify-around left-info-box">
                                    <div class="flex align-item">
                                        <img
                                            class="icon"
                                            src="@/assets/img/newIndex/bottomsite.png"
                                            alt=""
                                        />
                                        <div class="info">地址：广信区信州区广平街16号</div>
                                    </div>
                                    <div class="flex align-item">
                                        <img
                                            class="icon"
                                            src="@/assets/img/newIndex/bottomphone.png"
                                            alt=""
                                        />
                                        <div class="info">联系电话：0793-8440667</div>
                                    </div>
                                    <div class="flex align-item">
                                        <img
                                            class="icon"
                                            src="@/assets/img/newIndex/bottomtime.png"
                                            alt=""
                                        />
                                        <div class="info">
                                            服务时间：周一至周五，上午9:00-12:00，下午13:00-17:00
                                        </div>
                                    </div>
                                    <div class="flex align-item">
                                        <img
                                            class="icon"
                                            src="@/assets/img/newIndex/car.png"
                                            alt=""
                                        />
                                        <div class="info">
                                            乘车线路：20路;4路;9路;旅游观光线G001路内环乘坐至市行政中心西(公交站)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="right">
                                <h2 class="bottom-title">上饶就业创业 上饶人自己的招聘平台</h2>
                            </div>
                            <div class="qr-area flex">
                                <div class="qr-item">
                                    <img src="https://ftp.srrlzysc.com/3e753829-dba8-4487-b90c-9f67c7de3f73.jpg" alt="" class="qr" />
                                    <div class="qr-title">人工客服微信</div>
                                </div>
                                <div class="qr-item">
                                    <img src="https://ftp.srrlzysc.com/cf785a24-df22-47e5-9cf7-13cf998c0c32.png" alt="" class="qr" />
                                    <div class="qr-title">ios下载</div>
                                </div>
                                <div class="qr-item">
                                    <img src="https://ftp.srrlzysc.com/d3bb6c25-19fc-4380-87f6-17cc14bf5957.png" alt="" class="qr" />
                                    <div class="qr-title">安卓客户端</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="back-up">
                <div class="router-item">
                    江西省赣亿行企业管理有限公司是一家人力资源公司，成立于2018年，主要负责上饶企业人才招聘工作，与广信区理人资源市场达成深度合作。秉承“诚实守信”原则，创造良好的市场环境。
                </div>
                <a class="back-up-a" href="http://beian.miit.gov.cn">赣ICP备2020014976号</a>
            </div> -->
        </div>
<!--        <div class="back-up2">-->
<!--            <a class="back-up-a" href="http://beian.miit.gov.cn">赣ICP备2020014976号</a>-->
<!--        </div>-->

    </div>
</template>

<script>
export default {
    name: 'UserFooter',
};
</script>

<style lang="scss" scoped>
.text-lg {
    font-size: 20px;
}

.text-black {
    color: #333333;
}

.text-bold {
    font-weight: bold;
}
.qr {
    width: 78px;
    height: 78px;
}
.qr-title {
    line-height: 32px;
    font-size: 14px;
    color: #666;
    text-align: center;
}
.footer-box {
    /* 整体布局 */
    height: 240px;
    background-color: #ffffff;
    padding: 30px 0px;
}
.footer-content-box {
    width: 100%;
    .left {
        height: 200px;
        .left-info-box {
            height: 120px;
            padding: 20px 0px;
        }
        .info {
            line-height: 20px;
            font-size: 14px;
        }
    }

    .bottom-title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #333333;
        text-align: right;
        width: 100%;
        margin-bottom: 35px;
    }
}
.router-item {
    line-height: 32px;
    margin-top: 6px;
    color: #9A9FA7;
    font-size: 12px;
}
.back-up {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.back-up-a {
  text-decoration: none;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 12px;
    color: #9A9FA7;
}
.back-up2 {
    visibility: hidden;
}
.logo {
    width: 137px;
    height: 38px;
    margin: auto 0;
}
.icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}
@media screen and (max-width: 800px) {
    * {
        box-sizing: border-box;
    }
    .footer-box {
        display: none;
    }
    .back-up2 {
        visibility: visible;
        width: 100vw;
        height: 80px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .back-up-a {
            text-decoration: none;
            color: black;
            font-size: 20px;
        }
    }
}
.qr-area {
    display: flex;
    justify-content: flex-end;
}
.qr-item {
    margin-left: 32px;
}
</style>
