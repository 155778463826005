import request from './index'
const api = {
    jobFairList: '/jobFair/list',
    getJobFairDetail: '/jobFair/detailInfo',
    getIndustry: '/company/industry/selectAll',
    getNature: '/company/nature/selectAll',
    addMemberInfo: '/memberBaseInfo/addMemberInfo',
    addAuthInfo: '/memberAuthInfo/addMemberInfo',
    getBaseInfo: '/memberBaseInfo/selectMemberBaseInfo',
    releaseJob: '/work/addWork',
    queryJobs: '/work/selectMyWork',
    deleteJob: '/work/deleteWorkById',
    queryNums: '/work/QueryMyWorkNum',
    changeActionWork: '/work/changeActionWork',
    getJobInfo: '/work/selectWorkById',
    getJobList: '/work/yuYueMyWork',
    getFilterJobList: '/work/notInterviewMyWork',
    orderBoothLocation: '/booth/location',
    queryResumeList: '/resumeController/queryResumeByForm',
    getResumeInfo: '/resumeController/queryByResumeId',
    collectResumes: '/memResume/collectionResume',
    collectResumesInfo: '/memResume/selectMyCollectionResume',
    delCollectionResume: '/memResume/delCollectionResume',
    getAuthStatus: '/member/selectAuthStatus',
    getMemberInfo: '/member/selectMemberInfo',
    getInfoNumber: '/member/selectMemberInfoNumber',
    getRecommendResume: '/member/recommendResume',
    editCompanyInfo: '/member/makeInfoOrPassWord',
    addLegal: '/legal/memberAddLegal',
    queryLegal: '/legal/queryLegalByNameAndIDCard',
    checkLegalCardNumber: '/legal/checkLegal',
    postImg: '/qiniu/getImageUrl',
    getJobFairHistoryList: '/jobFair/historyList',
    queryAllIndustry: '/work/Industry/queryIndustry',
    getCityList: '/district/list',
    loginOut: '/memLoginOut/logOut',
    queryMyDeliveryResume: '/memResume/queryMyDeliveryResume',
    changeResumeStatus: '/memResume/changeResumeStatus',
    addInterview: '/interview/addInterview',
    queryMyInterview: '/interview/queryMyInterview',
    changeInterviewStatus: '/interview/changeInterviewStatus',
    queryVersion: '/hotUpdate/queryVersion',
    addHotUpdate: '/hotUpdate/addHotUpdate',
    getAllIntegralType: '/integral/getAllIntegralType',
    addIntegral: '/integral/addIntegral',
    getIntegralList: '/integral/selectAll',
    deleteIntegral: '/integral/deleteIntegral',
    selectMyRecord: '/integralRecord/selectMyRecord',
    checkResume: '/integral/isCheckResume',
    checkResumeIntegral: '/integral/checkResumeIntegral',
    getNumbers: '/member/myInterview',
    getEchartsData: '/member/myResumeView',
    changeIsFamous: '/member/changeIsFamous',
    addWorkTag: '/workTag/addWorkTag',
    getAllJobTag: '/workTag/QueryAllTag',
    deleteAllJobTag: '/workTag/deleteTag',
    selectTagNoPage: '/workTag/selectTagNoPage',
    changeWorkTags: '/work/changeWorkTags',
    selectUserById: '/admin/user/selectUserById',
    addSlideShow: '/img/addImg',
    getAllSlideShow: '/img/selectImg',
    deleteSlideShow: '/img/deleteImg',
    sendMessage: '/message/insert',  // 添加消息
    listByPid: '/message/listByPid',  // 消息大的列表
    messageList: '/message/list',  // 消息小的列表
    queryData: '/dataReporting/queryData',  // 消息小的列表
    insertData: '/dataReporting/insertData',  // 消息小的列表
    editData: '/dataReporting/editData',  // 消息小的列表
    delData: '/dataReporting/delData',  // 消息小的列表
    
}
const companyRequest = {
    //上传图片接口
    postImg(data) {
        return request.post(api.postImg, data)
    },
    //退出登录
    loginOut() {
        return request.get(api.loginOut)
    },
    //历史数据(招聘效果页面)
    getNumbers() {
        return request.get(api.getNumbers)
    },
    //echarts图表数据(招聘效果页面)
    getEchartsData(data) {
        return request.get(api.getEchartsData, data)
    },
    //获取积分类型(管理员积分管理页面)
    getAllIntegralType() {
        return request.get(api.getAllIntegralType)
    },
    //获取积分列表(管理员积分管理页面)
    getIntegralList() {
        return request.get(api.getIntegralList)
    },
    //删除某条积分(管理员积分管理页面)
    deleteIntegral(data) {
        return request.get(api.deleteIntegral, data)
    },
    //保存或修改积分类型(管理员积分管理页面)
    addIntegral(data) {
        return request.post(api.addIntegral, data)
    },
    //更改企业级别(管理员企业管理页面)
    changeIsFamous(data) {
        return request.post(api.changeIsFamous, data)
    },
    //职位标签列表(管理员职位列表页面)
    selectTagNoPage() {
        return request.get(api.selectTagNoPage)
    },
    //保存职位标签(管理员职位列表页面)
    changeWorkTags(data) {
        return request.post(api.changeWorkTags, data)
    },
    //添加职位标签(管理员职位标签页面)
    addWorkTag(data) {
        return request.post(api.addWorkTag, data)
    },
    //获取所有职位标签(管理员职位标签页面)
    getAllJobTag() {
        return request.get(api.getAllJobTag)
    },
    //删除职位标签(管理员职位标签页面)
    deleteAllJobTag(data) {
        return request.get(api.deleteAllJobTag, data)  
    },
    //查询用户信息(管理员用户管理页面)
    selectUserById(data) {
        return request.get(api.selectUserById, data)
    },
    //添加修改轮播图(管理员轮播图管理页面)
    addSlideShow(data) {
        return request.post(api.addSlideShow, data)
    },
    //获取轮播图列表(管理员轮播图管理页面)
    getAllSlideShow(data) {
        return request.get(api.getAllSlideShow,data)
    },
    //删除轮播图(管理员轮播图管理页面)
    deleteSlideShow(data) {
        return request.get(api.deleteSlideShow, data)
    },

    //查询积分(企业积分明细页面)
    selectMyRecord(data) {
        return request.post(api.selectMyRecord, data)
    },
    //获取热更新版本号(管理员热更新页面)
    queryVersion() {
        return request.get(api.queryVersion)
    },
    //保存热更新(管理员热更新页面)
    addHotUpdate(data) {
        return request.post(api.addHotUpdate, data)
    },
    //获取企业认证审核状态(layout1页面)
    getAuthStatus() {
        return request.get(api.getAuthStatus)
    },
    //邀约面试(收到的简历页面)
    addInterview(data) {
        return request.post(api.addInterview, data)
    },
    //获取投递简历(收到的简历页面)
    queryMyDeliveryResume(data) {
        return request.post(api.queryMyDeliveryResume, data)
    },
    //企业标记简历状态(收到的简历页面)
    changeResumeStatus(data) {
        return request.post(api.changeResumeStatus, data)
    },
    //获取招聘会列表(招聘会预约页面)
    getJobFairList(data) {
        return request.post(api.jobFairList, data)
    },
    //获取企业参加招聘会历史列表(招聘会预约历史页面)
    getJobFairHistoryList(data) {
        return request.post(api.getJobFairHistoryList, data)
    },
    //获取招聘会和展位相关信息（在线预约页面）
    getJobFairDetail(data) {
        return request.get(api.getJobFairDetail, data)
    },
    //获取职位列表(在线预约页面)
    getJobList() {
        return request.get(api.getJobList)
    },
    //获取过滤职位(简历详情，邀请面试)
    getFilterJobList(data) {
        return request.get(api.getFilterJobList, data) 
    },
    //预定展位(在线预约页面)
    orderBoothLocation(data) {
        return request.get(api.orderBoothLocation, data)
    },
    //查询所有职位分类(发布职位页面)
    queryAllIndustry() {
        return request.get(api.queryAllIndustry)
    },
    //获取省市区信息(发布职位页面)
    getCityList(data) {
        return request.get(api.getCityList, data)
    },
    //发布职位(发布职位页面)
    releaseJob(data) {
        return request.post(api.releaseJob, data)
    },
    //查询职位信息(发布职位页面)
    getJobInfo(data) {
        return request.get(api.getJobInfo, data)
    },
    //选择职位(职位管理页面)
    queryJobs(data) {
        return request.post(api.queryJobs, data)
    },
    //删除职位(职位管理页面)
    deleteJob(data) {
        return request.get(api.deleteJob, data)
    },
    //职位操作：重新招聘或暂停(职位管理页面)
    changeActionWork(data) {
        return request.post(api.changeActionWork, data)
    },
    //获取数字统计(职位管理页面)
    queryNums() {
        return request.get(api.queryNums)
    },
    //获取公司行业(数据中心页面)
    getIndustry() {
        return request.get(api.getIndustry)
    },
    //获取公司性质(数据中心页面)
    getNature() {
        return request.get(api.getNature)
    },
    //保存公司基本信息(数据中心页面)
    addMemberInfo(data) {
        return request.post(api.addMemberInfo, data)
    },
    //获取企业基本信息(数据中心页面)
    getBaseInfo() {
        return request.get(api.getBaseInfo)
    },
    //保存企业认证信息(企业认证中心页面)
    addAuthInfo(data) {
        return request.post(api.addAuthInfo, data)
    },
    //添加招聘负责人信息(企业认证中心页面)
    addLegal(data) {
        return request.post(api.addLegal, data)
    },
    //查询招聘负责人身份证是否存在(企业认证中心页面)
    checkLegalCardNumber(data) {
        return request.get(api.checkLegalCardNumber, data)
    },
    //选择招聘负责人
    queryLegal(data) {
        return request.post(api.queryLegal, data)
    },
    //简历列表(简历中心页面)
    queryResumeList(data) {
        return request.post(api.queryResumeList, data)
    },
    //企业看简历是否需要消耗积分(简历中心页面)
    checkResume(data) {
        return request.get(api.checkResume, data)
    },
    //消耗积分查看简历(简历中心页面)
    checkResumeIntegral() {
        return request.get(api.checkResumeIntegral)
    },
    //收藏列表(收藏的简历页面)
    collectResumesInfo(data){
        return request.post(api.collectResumesInfo, data)
    },
    //删除收藏简历(收藏的简历页面)
    delCollectionResume(data) {
        return request.get(api.delCollectionResume, data)
    },
    //简历详细信息(详细简历页面)
    getResumeInfo(data) {
        return request.get(api.getResumeInfo, data)
    },
    //收藏简历(详细简历页面)
    collectResumes(data) {
        return request.get(api.collectResumes, data)
    },
    //获取基本信息(我的页面)
    getMemberInfo() {
        return request.get(api.getMemberInfo)
    },
    //获取数字统计(我的页面)
    getInfoNumber() {
        return request.get(api.getInfoNumber)
    },
    //获取简历(我的简历)
    getRecommendResume() {
        return request.get(api.getRecommendResume)
    },
    // 修改企业LOGO、密码
    editCompanyInfo(data) {
        return request.post(api.editCompanyInfo, data)
    },
    //查看邀请面试列表(面邀记录页面)
    queryMyInterview(data) {
        return request.post(api.queryMyInterview, data)
    },
    //反馈面试邀请的简历(面邀记录页面)
    changeInterviewStatus(data) {
        return request.post(api.changeInterviewStatus, data)
    },
    sendMessage(data) {
        return request.post(api.sendMessage, data)
    },
    listByPid(data) {
        return request.get(api.listByPid, data)
    },
    messageList(data) {
        return request.get(api.messageList, data)
    },
    queryData(data) {
        return request.get(api.queryData, data)
    },
    insertData(data) {
        return request.post(api.insertData, data)
    },
    editData(data) {
        return request.post(api.editData, data)
    },
    delData(data) {
        return request.get(api.delData, data)
    },
}
export default companyRequest