<template>
    <div class="loginbox">
        <div class="logintop flex align-center justify-between">
            <div class="logo" @click="$router.push('/')">
                <img src="/img/newlogo2.png" alt="">
            </div>
            <span>客户服务热线：0793-8440667</span>
        </div>
        <div class="logincont flex align-center justify-between">
            <div>
                <img src="/img/logopic.png" width="500" alt="">
            </div>
            <div class="logincontright bg-white">
                <template v-if="!isforget">
                    <h2 class="padding-bottom-sm" v-if="isRegister">注册</h2>
                    <h2 class="padding-bottom-sm" v-if="!isRegister">欢迎登录</h2>
                </template>
                <template v-if="isforget">
                    <h2 class="padding-bottom-sm" >忘记密码</h2>
                </template>

                <div class="formbox " v-if="!isforget">
                    <div class="formitem margin-top-sm">
                        <el-input v-model="phoneNumber" size="large" placeholder="手机号"></el-input>
                    </div>
                    <div class="formitem margin-top-sm">
                        <el-input v-model="password" type="password" show-password size="large" placeholder="密码"></el-input>
                    </div>
                    <div class="formitem margin-top-sm" v-show="isRegister">
                        <el-input maxLength="6" size="large" v-model="verificationCode" placeholder="请输入验证码">
                            <template slot="append">
                                <span class="cursor-pointer" v-show="codeShow" @click="sendPhoneCode">{{codeTitle}}</span>
                                <span class="cursor-pointer" v-show="!codeShow" >{{timeCount}} 秒</span>
                            </template>
                        </el-input>
                    </div>

                    <div class="formitem margin-top-sm">
                        <el-button type="primary" v-show="!isRegister" :loading="btnLoading" @click="login" size="large" style="width:100%;">登录</el-button>
                        <el-button type="primary" v-show="isRegister" :loading="btnLoading" @click="register" size="large" style="width:100%; margin-left:0;">注册</el-button>
                    </div>
                    <div class="formitem margin-top-sm flex align-center justify-between" style="margin-top:30px;">
                        <span v-show="!isRegister">还没有账号？<i class="text-blue cursor-pointer" style="font-style:normal;" @click="registerMode">立即注册</i></span>
                        <span v-show="isRegister">已经有账号？<i class="text-blue cursor-pointer" style="font-style:normal;" @click="loginMode">直接登录</i></span>
                        <span class="cursor-pointer" @click="isforget=true">忘记密码？</span>
                    </div>
                </div>

                <div class="formbox " v-if="isforget">
                    <div class="formitem margin-top-sm">
                        <el-input v-model="mobile" size="large" placeholder="手机号"></el-input>
                    </div>
                    <div class="formitem margin-top-sm" >
                        <el-input maxLength="6" size="large" v-model="verificationCode" placeholder="请输入验证码">
                            <template slot="append">
                                <span class="cursor-pointer" v-show="codeShow" @click="sendPhoneCode2">{{codeTitle}}</span>
                                <span class="cursor-pointer" v-show="!codeShow" >{{timeCount}} 秒</span>
                            </template>
                        </el-input>
                    </div>
                    <div class="formitem margin-top-sm">
                        <el-input v-model="passwordOne" type="password" show-password size="large" placeholder="新密码"></el-input>
                    </div>
                    <div class="formitem margin-top-sm">
                        <el-input v-model="passwordTwo" type="password" show-password size="large" placeholder="确认新密码"></el-input>
                    </div>
                    <div class="formitem margin-top-sm">
                        <el-button type="primary"  @click="confirm" size="large" style="width:100%; margin-left:0;">确认修改</el-button>
                    </div>
                    <div class="formitem margin-top-sm flex align-center justify-between" style="margin-top:30px;">
                        <span>突然想起密码了！<i class="text-blue cursor-pointer" style="font-style:normal;" @click="isforget=false">返回登录</i></span>
                        <span class="cursor-pointer" ></span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import userRequest from "@/api/user";
    const TIME_COUNT = 60;
    export default {
        mounted(){
            window.localStorage.setItem("roleId", JSON.stringify(99));
            let type=this.$route.query.type
            if(type==1){
                this.isforget=true
            }else if(type==2){
                this.registerMode()
            }
        },
        data() {
            return {
                isforget:false,
                isRegister: false,
                phoneNumber:"",
                password:"",
                verificationCode:"",
                codeTitle:"获取验证码",  // 获取验证码的提示语
                codeShow:true, // 是否展示倒计时
                timeCount:"",       // 倒计时的时间
                timeInter:null,     // 倒计时的interval
                btnLoading:false, // 按钮加载图标
                passwordOne: "",
                passwordTwo: "",
                mobile:""
            }
        },
        methods:{
            sendPhoneCode2() {
                console.log(this.mobile)
                const telReg = /^[1]([3-9])[0-9]{9}$/
                if(!telReg.test(this.mobile)){
                    this.$message.error("请检查手机号码是否正确")
                    return;
                }
                userRequest.sendPhoneCodeForPassword({phone: this.mobile}).then(res => {
                    if (res.code === 200) { // 发送成功
                        this.$message.success("验证码发送成功")
                        if (!this.timeInter) {
                            this.timeCount = TIME_COUNT;
                            this.codeShow = false;
                            this.timeInter = setInterval(() => {
                                if (this.timeCount > 0 && this.timeCount <= TIME_COUNT) {
                                    this.timeCount--;
                                } else {
                                    this.codeShow = true;
                                    this.codeTitle = "重新发送"
                                    clearInterval(this.timeInter);
                                    this.timeInter = null;
                                }
                            }, 1000)
                        }
                    } else {
                        this.$message.error("发送失败，请稍后重试")
                    }
                })
            },
            confirm(){
                if(this.verificationCode.length!==6){
                    this.$message.warning("请确认验证码")
                    return;
                }

                if(this.passwordOne!==this.passwordTwo){
                    this.$message.warning("两次密码不一致")
                    return;
                }

                userRequest.forgetPassword({
                    phone:this.mobile,
                    password:this.passwordOne,
                    code:this.verificationCode
                }).then(res=>{
                    if(res.success){  // 密码重置成功
                        console.log('密码重置成功',res)
                        this.$message.success("设置成功")
                        this.isforget=false
                        this.isRegister=false
                    }else{
                        this.$message.error(res.message)
                    }
                }).catch(err=>{
                    this.$message.error(err)
                })
            },
            routeTo(path) {
                if (path === this.$route.path) {
                    return
                }
                this.$router.push({
                    path: path
                })
            },

            registerMode(){
              this.isRegister = true
                // this.$message.success("升级中，暂未开放")
            },
            loginMode(){
                this.isRegister = false
            },
            sendPhoneCode(){  /* 发送验证码 */
                const telReg = /^[1]([3-9])[0-9]{9}$/
                if(!telReg.test(this.phoneNumber)){
                    this.$message.error("请检查手机号码是否正确")
                    return;
                }
                userRequest.sendPhoneCode({phone:this.phoneNumber}).then(res=>{

                    if(res.code === 200){ // 发送成功
                        if (!this.timeInter) {
                            this.timeCount = TIME_COUNT;
                            this.codeShow = false;
                            this.timeInter = setInterval(() => {
                                if (this.timeCount > 0 && this.timeCount <= TIME_COUNT) {
                                    this.timeCount--;
                                } else {
                                    this.codeShow = true;
                                    this.codeTitle = "重新发送"
                                    clearInterval(this.timeInter);
                                    this.timeInter = null;
                                }
                            }, 1000)
                        }
                    }else if(res.code===400){
                        console.log(565656)
                        this.$message.error(res.message)
                    }else{
                        this.$message.error("发送失败，请稍后重试")
                    }
                })

            },
            /**
             * // 请求后台，完成注册，注册成功以后先登录，可以浏览职位
             * 如果要投递简历或者其他操作，需要补充个人信息 + 创建简历
             */
            register(){
                // this.$message.success("升级中，暂未开放")
                const telReg = /^[1]([3-9])[0-9]{9}$/
                if(!telReg.test(this.phoneNumber)){
                    this.$message.error("请检查手机号码是否正确")
                    return;
                }

                let codeLength = this.verificationCode.length
                if(codeLength===6){

                }else{
                    this.$message.error("请输入正确的验证码")
                    return;
                }
                const pwdReg = /^[A-Za-z0-9]+$/
                if(this.password.length<6||this.password.length>12){
                    this.$message.error("密码仅支持6~12位")
                    return;
                }
                if(!pwdReg.test(this.password)){
                    this.$message.error("密码仅支持数字和字母")
                    return;
                }

                // return;

                let requestParams = {
                    userName:this.phoneNumber,
                    password:this.password,
                    phone:this.phoneNumber,
                    code:this.verificationCode  // 验证码
                }

                userRequest.userRegister(requestParams).then(res=>{
                    console.log("注册的返回值",res)
                    if(res.code === 200){
                        this.$message.success("注册成功,请直接登录")
                        this.isRegister = false
                    }


                })

            },
            login(){  // 登录成功跳转首页
                this.btnLoading = true
                userRequest.userLogin({phone: this.phoneNumber, password: this.password, type: 1}).then((res) => {
                    // console.log("登录的返回值",res)
                    if (res.code === 200) {
                        let userInfo = res.data
                        userInfo.roleId = 99
                        console.log('userInfo', userInfo)
                        window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
                        window.localStorage.setItem("currentClient",'user')   // 标记，当前的客户端，user：用户端
                        window.localStorage.setItem("hasLogin", 'true')  // 标记，表示现在已经登录
                        this.$message.success("登录成功")
                        setTimeout(() => {
                            this.btnLoading = false
                            this.$router.push({path: "/user/resumes"}).catch(() => {});
                        }, 1000)
                    }else{
                        this.btnLoading = false
                        this.$message.warning("登录失败，请检查账号或者密码是否正确")
                    }
                }).catch(err=>{
                    this.btnLoading = false
                    this.$message.warning("登录失败，请检查账号或者密码是否正确")
                })

                setTimeout(()=>{
                    this.btnLoading = false
                },5000)
            }
        }

    }
</script>
<style scoped>
.loginbox{ width: 100%; height: 100vh; background: #ffffff;  overflow: hidden;background-size: cover;}
.logintop{ width: 1200px; margin: 25px auto 0 auto;}
.logincont{ width: 1100px; margin: 120px auto 0 auto;}
.logincontright{ height: auto; width: 434px; padding: 40px; box-shadow: rgba(0,0,0,0.1) 0 0 18px; border-radius: 3px;}
.logincontright h2{ text-align: center;}



</style>

