<template>
<div class="loginbigbox">
    <div class="bgbox"></div>
    <div class="loginbox flex align-center justify-center">
        <div class="logincont bg-white">
            <h1>人力资源管理系统</h1>
            <div class="loginform">
                <el-form ref="adminLogForm" :rules="rules" :model="adminLogForm">
                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="请输入登录名" size="large" @keyup.enter.native="logIn('adminLogForm')" v-model="adminLogForm.account">
                            <template slot="prepend"><i class="el-icon-user" style="font-size:18px;"></i></template>
                        </el-input>
                    </div>
                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="请输入密码" size="large" :type="type" @keyup.enter.native="logIn('adminLogForm')" v-model="adminLogForm.passWord">
                            <template slot="prepend"><i class="el-icon-lock" style="font-size:18px;"></i></template>
                            <template slot="append">
                                <i class="el-icon-view cursor-pointer" @click="changeType" style="font-size:18px; color:#ccc;" > </i>
                            </template>
                        </el-input>
                    </div>
                    <div class="formitem">
                        <el-button type="primary" v-preventReClick size="medium" @click="logIn('adminLogForm')" style="width:100%;">登录</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</div>
    
</template>
<script>
    import _api from "@/api/index";
    import apiUrl from "@/api/public";
    import adminRouter from "@/router/adminList";

    let checkMobile = (rule, value, cb) => {
        const regMobile = /^1[0-9]{10}$/

        if (regMobile.test(value)) {
            return cb()
        }
        cb(new Error('请输入合法的手机号'))
    }
    let checkpassword = (rule, value, cb) => {
        // const regPassword = /^[a-z0-9]{6,16}$/
        const regPassword = /^[0-9a-zA-Z]{6,12}$/g
        if (regPassword.test(value)) {
            return cb()
        }
        cb(new Error('密码只能由6-12位字母或数字组成'))
    }
    export default {
        components: {},
        created() {
            window.localStorage.setItem("roleId", JSON.stringify(1));

        },
        data() {
            return {
                bgImg: require("../../assets/loginBG.jpg"),
                adminLogForm: {
                    account: "",
                    passWord: "",
                },
                rules: {
                    account: [
                        {required: true, message: "请输入用户名", trigger: "blur"},
                    ],
                    passWord: [{required: true, message: "请输入密码", trigger: "blur"}, {
                        validator: checkpassword,
                        trigger: 'blur'
                    }],
                },
                rulesList: [
                    "主页",
                    "简历详情",
                    "/newLogin",
                    '/newUserLogin',
                    "/newAdminLogin",
                    "/home",
                    "/",
                    "/user/userCenter",
                    "/information/add",
                    "/datareport/index",
                    "/datareport/show",
                    "添加信息",
                    "/information/edit",
                    "/jobfair/edit",
                    "/jobfair/history",
                    "编辑招聘会",
                    "/jobfair/show",
                    "/bigdata/industry",
                    "/bigdata/flow",
                    "/bigdata/position",
                    "招聘会详情",
                    '/hotPatch',
                    '/IntegralManagement',
                    '/booth/history'
                ],
                menuList: [],
                url: "/rolePer/getPermission",
                type:"password"
            };
        },
        methods: {
            logIn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let url = this.url;
                        let {account, passWord} = this.adminLogForm;
                        _api.post(apiUrl.login, {
                            account: account,
                            password: passWord,
                            loginType: 1,
                        }).then((res) => {
                            //刷新这个区的位置列表
                            if (res.success) {
                                this.$message.success("登录成功");
                                window.localStorage.setItem(
                                    "userInfo",
                                    JSON.stringify(res.data)
                                );
                                window.localStorage.setItem(
                                    "roleId",
                                    JSON.stringify(res.data.roleId)
                                );
                                window.localStorage.setItem(
                                    "schoolId",
                                    JSON.stringify(res.data.schoolId)
                                );
                                
                                _api.get(apiUrl.district,{id:240}).then(ress=>{
                                    if(ress.code==200){
                                        localStorage.setItem("org",JSON.stringify(ress.data))
                                        
                                        if(res.data.orgId!=0){
                                            let find=ress.data.find((n)=>(n.orgId==res.data.orgId))
                                            console.log(find)
                                            if(find){
                                                 window.localStorage.setItem("curorg",JSON.stringify(find))
                                                 console.log(111)
                                            }else{
                                                window.localStorage.setItem('curorg', null)  // token
                                                console.log(222)
                                            }
                                        }
                                        
                                    }
                                })
                                
                                _api.get(url, {}).then((res) => {
                                        // console.log(1);
                                        if (res.success) {
                                            this.menuList = res.data;  // 更改菜单列表，监听菜单列表的变化来控制路由
                                            // console.log('menuList', that.menuList)
                                        }
                                    }).catch((err) => {
                                        // console.log(err);
                                        this.$message.error(err);
                                    });
                            }
                        })
                            .catch((err) => {
                                // console.log(err);
                                this.$message.error(err);
                            });
                    } else {
                        this.$message.error("信息未填写完整，请检查！");
                        return false;
                    }
                });
            },
            changeType(){
                if(this.type){
                    this.type=''
                }else{
                    this.type="password"
                }
            },
            getMenuList() {
                _api
                    .get(this.url, {})
                    .then((res) => {
                        if (res.success) {
                            this.menuList = res.data;
                            // console.log("menuList", this.menuList);
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                        this.$message.error(err);
                    });
            },
            getRules(arr) {
                arr.forEach((item, index) => {
                    if (item.child.length > 0) {
                        item.child.forEach((item2, index2) => {
                            this.rulesList.push(item2.name);
                            this.rulesList.push(item2.page);
                        });
                    }
                });
                // console.log('rulesList', this.rulesList)
                window.localStorage.setItem("hasLogin", 'true')  // 登录标记
                // console.log(adminRouter)
                window.sessionStorage.setItem("adminList",JSON.stringify(this.rulesList))  // 在回话中添加  路由信息，防止被查看
                // adminRouter.adminList = JSON.stringify(this.rulesList)  // 替换js文件中的数据
                window.localStorage.setItem("currentClient", 'admin')  // 客户端标记
                // window.localStorage.setItem("rulesList", JSON.stringify(this.rulesList));  // 存储到本地可以被随意修改，转移到路由里调用
            },
        },
        watch: {
            menuList(val) {
                // console.log("val", val);
                this.getRules(val);
                setTimeout(() => {
                    this.$router.push({path: "/home"}).catch(() => {
                    });
                }, 1500);
            },
        },
    };
</script>
<style scoped>
.loginbigbox{ position: relative;width: 100%; height: 100vh;}
.bgbox{position: absolute;width: 100%; opacity: 0.7; height: 100vh;background: url("https://hpk2.quxingcm.com/humanbg.jpg") no-repeat; background-size: cover; z-index: 1;}
.loginbox{  position: absolute;width: 100%; height: 100vh; z-index: 100; }
.logincont{width: 500px; height: 440px;border-radius: 8px;    box-shadow: 0 6px 20px 5px #9898981a, 0 16px 24px 2px #75757524;}
.logincont h1{margin-top: 80px; text-align: center;font-size: 33px; color:#2c3e50;}
.loginform{ padding: 0 50px; margin-top: 50px;}

.el-main{ padding: 15px!important;}
.box{ flex: 1; padding: 15px;}
</style>