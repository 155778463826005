import Vue from 'vue'
import VueRouter from 'vue-router'
import routeMaps from './route.js'
import {Message} from 'element-ui'
import newLogin from '../views/login/newLogin.vue'
import newAdminLogin from '../views/login/newAdminLogin.vue'
// import newUserLogin from '../views/login/newUserLogin.vue'
import newUserLogin from '../pages/users/public/register'
import userRouter from "@/router/userList";
import adminRouter from "@/router/adminList";
import companyRouter from "@/router/companyList";
import _api from "@/api/index";  // 引入请求


Vue.use(VueRouter)
let userManager = null
let userInfo = null
let authInfoStatus = null
let rulesList = null  // 路由白名单，不同的客户端切换不痛的路由白名单

let otherList =
    ['/newLogin',
        '/newAdminLogin',
        '/newUserLogin',
    ]
// let companyWhiteList = ['/company/postJob', '/company/resumeCenter', '/company/notice', '/company/orderBooth', '/company/downloadResume', '/company/collection', '/company/resumeInfo', '/company/jobManagement', '/company/mine', '/company/certification', '/company/companyInfo', '/company/settings']
// 定义一些通用页面 登录 404等
const routeCommon = [
    {
        path: '/newLogin',
        name: 'newLogin',
        meta: {
            title: '新登录页面'
        },
        component: newLogin
    },
    {
        path: '/newAdminLogin',
        name: 'newAdminLogin',
        meta: {
            title: '新管理员登录页面'
        },
        component: newAdminLogin
    },

    // /user/public/register
    {
        path: '/newUserLogin',
        name: 'newUserLogin',
        meta: {
            title: '新用户员登录页面'
        },
        component: newUserLogin
    },
]
const router = new VueRouter({
    routes: [...routeMaps, ...routeCommon]
})
router.beforeEach((to, from, next) => {
    let currentClient = window.localStorage.getItem("currentClient")  //       获取当前的客户端
    let token = ''
    let tokenStr = window.localStorage.getItem('token')
    // console.log("本地获取到的token",tokenStr,Boolean(tokenStr))
    if(tokenStr){
        token = JSON.parse(tokenStr)
    }
    if (token) {  //  已经登录，载入路由信息
        if (currentClient === 'admin') {  // 管理员端，载入管理员路由表 用sessionStorage 载入
            const adminList = JSON.parse(window.sessionStorage.getItem('adminList'))
            if (adminList && adminList.length > 0) {
                rulesList = adminList
            }
        } else if (currentClient === 'company') {  //  企业端，企业路由表
            console.log(1111122222)
            rulesList = companyRouter.companyList
            // console.log("获取到的企业端的路由白名单", rulesList, companyRouter.companyList)
        } else if (currentClient === 'user') {  // 用户端
            rulesList = userRouter.userTokenList
        }
    }


    if (to.path == "/") {   // 用户端,跳转到首页

        next("/user/tempIndex")
        return
    }

    if (userRouter.userList.includes(to.path)) {  // 用户端的路由白名单,无需token就能进入的页面
        // console.log("无需权限")
        next()
        return
    }

    if (otherList.includes(to.path)) {  // 路由白名单，任何情况下都直接放行，公开界面
        next()
        return
    }

    authInfoStatus = window.localStorage.getItem('authInfoStatus')     // 获取企业的认证状态
    if (to.meta.title) {        // 有标题的页面
        document.title = to.meta.title + '--上饶就业创业'
    }
    if (!!token) {   //   有token，代表已经登录
        if (!!rulesList && rulesList.length > 0) {  // 有路由白名单
            if (rulesList.includes(to.name) || rulesList.includes(to.path)) {
                next()
                return;
            } else {
                Message({
                    type: 'warning',
                    message: '这个页面丢失了...'
                })
                next({path: from.path})
            }
        } else {  // 没有路由白名单

            next('/user/tempIndex')
        }
    } else { //没有登录token的时候
        // console.log("当前没有token,返回首页")
        Message.warning("请先登录！")
        setTimeout(()=>{
            next('/user/tempIndex')
        },1000)

    }

})
export default router
