import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import UploadImg from '@/components/Uploadimg'
import Popup from '@/components/Popup'
import ShowImg from '@/components/Showimg'
import AppPage from '@/components/pagination/AppPagination'
import  VueQuillEditor from 'vue-quill-editor'
//import './theme/index.css'//element ui主题
import './element-variables.scss'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import preventReClick from '@/util/preventReClick' //防多次点击，重复提交
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import eCharts from 'echarts'
import Print from 'vue-print-nb'
import {createTrtcCalling} from './trtc-calling';
import TRTCCalling from 'trtc-calling-js';
import '../public/ueditor/ueditor.config.js'
import '../public/ueditor/ueditor.all.js'
import '../public/ueditor/lang/zh-cn/zh-cn.js'
import '../public/ueditor/ueditor.parse.js'
import moment from 'moment'
Vue.prototype.$moment = moment

/**
 *   ls: localStorage
 *   本地存储封装调用
 *   this.$ls.set("name","张三")
 *  let name =  this.$ls.get("name")
 *  console.log(name)
 *
 */

import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage, {
  name: 'ls',
  bind: true //created computed members from your variable declarations
})


/*
*  vue 地区选择器
*
* https://github.com/dwqs/vue-area-linkage
* */
import 'vue-area-linkage/dist/index.css'; // v2 or higher
import VueAreaLinkage from 'vue-area-linkage';
Vue.use(VueAreaLinkage)



Vue.use(Print)
import AMap from 'vue-amap'
Vue.use(AMap)
// 初始化vue-amap
AMap.initAMapApiLoader({
  key: '494d7467e611b3398f64731afa7af2a5',
  plugin: ['AMap.Geolocation', 'AMap.PlaceSearch']
})
Vue.prototype.$echarts = eCharts

Vue.component('upload-img',UploadImg)//单个图片上传组件
Vue.component('pop-up',Popup)//弹框组件
Vue.component('show-img',ShowImg)//图片展示组件
Vue.component('app-page',AppPage)//分页组件
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });
Vue.use(VueQuillEditor)
Vue.use(VideoPlayer)
Vue.prototype.$trtcCalling = createTrtcCalling();
Vue.prototype.TrtcCalling = TRTCCalling;


Vue.config.productionTip = false
// Vue.use(ElementUI, { zIndex: 2 });
// Vue.prototype.$ELEMENT = {zIndex:2}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

