<template>
    <div class="topmenubox">
        <div class="nav" v-if="!showsmallmenu">
            <div class="top-menu">
                <div class="left flex align-center">
                     欢迎来到广信区就业通！
                    <!-- <img src="@/assets/img/newIndex/site.png" alt="" class="site" /> -->
                    <!-- <span class="city">{{$store.state.org.title}}</span> -->
                    <!-- <a class="change cursor-pointer" @click="$router.push('/user/changeweb')">[切换分站]</a> -->
                </div>

                <div class="right flex align-center">
                    <div v-if="userInfo && roleId == 99" class="flex align-center" style="margin-right:15px;">
                        <!-- <span>{{ userInfo.userName }}</span> -->
                        <el-dropdown>
                            <span class="el-dropdown-link">
                            {{ userInfo.userName }} <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                            <!--                                <div @click="routeTo('/user/resumes')"><el-dropdown-item><i class="el-icon-s-home"></i> 基本信息</el-dropdown-item></div>-->
                            <div @click="routeTo('/user/resumes')">
                                <el-dropdown-item
                                ><i class="el-icon-s-home"></i> 我的简历</el-dropdown-item
                                >
                            </div>
                            <div @click="routeTo('/user/settingInfo')">
                                <el-dropdown-item
                                ><i class="el-icon-s-tools"></i>
                                账号设置</el-dropdown-item
                                >
                            </div>
                            <div @click="$router.push({ path: '/user/realname' })">
                                <el-dropdown-item
                                ><i class="el-icon-user-solid"></i>
                                实名认证</el-dropdown-item
                                >
                            </div>
                            <div @click="$router.push({ path: '/user/smartjob' })">
                                <el-dropdown-item
                                ><i class="el-icon-user-solid"></i>
                                智能岗位推荐</el-dropdown-item
                                >
                            </div>
                            <div @click="routeTo('/user/videohome')">
                                <el-dropdown-item
                                ><i class="el-icon-video-camera-solid"></i>
                                视频面试</el-dropdown-item
                                >
                            </div>
                            <div @click="routeTo('/user/sendlist')">
                                <el-dropdown-item
                                ><i class="el-icon-s-comment"></i>
                                消息管理</el-dropdown-item
                                >
                            </div>
                            <div @click="userLogout">
                                <el-dropdown-item
                                ><i class="el-icon-message-solid"></i
                                >退出登录</el-dropdown-item
                                >
                            </div>
                            </el-dropdown-menu>
                        </el-dropdown>

                    </div>
                    <div v-else-if="userInfo && roleId == 2">
                        <span class="cursor-pointer" @click="$router.push({path:'/company/mine',query:{needAuthCheck:true}})">企业中心</span>
                    </div>
                    <template v-else>
                        <img src="@/assets/img/newIndex/user.png" alt="" class="menu-icon" />
                        <a class="menu-item cursor-pointer" @click="$router.push('/newUserLogin')">个人登录</a>
                        <img src="@/assets/img/newIndex/enterprice.png" alt="" class="menu-icon" />
                        <a class="menu-item cursor-pointer" @click="$router.push('/newLogin')">企业登录</a>
                    </template>
                    <!-- <img src="@/assets/img/newIndex/phone.png" alt="" class="menu-icon" />
                    <span class="menu-item right0">0793-8440667</span> -->
                </div>
            </div>
            <div class="search-bar flex">
                <img src="@/assets/img/newIndex/icon.png" @click="$router.push('/')" style="width:150px;" alt="" class="icon" />
                <div class="search flex">
                    <el-select size="mini" v-model="searchselect" placeholder="请选择">
                        <el-option label="职位" :value="1">职位</el-option>
                        <el-option label="公司" :value="2">企业</el-option>
                    </el-select>
                    <el-input style="width: 675px;" placeholder="请输入关键词" v-model="searchJobName" class="input" />
                    <!-- <div class="btn">
                    <img src="@/assets/img/newIndex/search.png" alt="" class="search-icon" />
                </div> -->
                    <el-button  class="btn" @click="searchJob">
                        <i class="el-icon-search" style="font-size:20px"></i>
                    </el-button>
                </div>
                <div style="width: 50px; height: 50px; margin-top: 9px; margin-left: 20px;">
                    <el-popover
                        placement="bottom"
                        title=""
                        width="330"
                        trigger="hover"
                        content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                        <div class="downbox">
                            <div class="flex align-center justify-between">
                                <div class="downleft">
                                    <a target="_blank" href="https://apps.apple.com/cn/app/%E5%B9%BF%E4%BF%A1%E5%B0%B1%E4%B8%9A%E9%80%9A/id1659608820" class="downleftitem flex align-center">
                                        <i class="iconfont icon-ios"></i>
                                        <span>IOS下载</span>
                                    </a>
                                    <a target="_blank" href="http://az.srdxg.com/4tz2?utm_source=fir&utm_medium=qr" class="downleftitem downleftitem2 flex align-center">
                                        <i class="iconfont icon-anzhuo"></i>
                                        <span>安卓下载</span>
                                    </a>
                                </div>
                                <div class="downright"><img style="width:120px;height: 120px;" src="http://data.srgxjy.com/downerw.png" alt="" class="qr" /></div>
                            </div>
                        </div>
                        <img  slot="reference" src="/img/downerw.png" style="width: 50px; height: 50px;" alt="" class="qr" />
                    </el-popover>
                </div>
            </div>
            <div class="menu">
                <ul>
                    <li
                        v-for="(item, index) in navList"
                        :key="index"
                        class="li-item"
                        :class="{ link: hoverIdx === index + 1 }"
                        @click="openPage(index)"
                    >
                        <span>
                            {{ item.title }}
                        </span>

                    </li>
                </ul>
            </div>
        </div>

        <div v-if="showsmallmenu" style="height:190px;"></div>
        <div class="smallnavbox" v-if="showsmallmenu">
            <div class="user-header2 nav" >
                <div class="search-bar flex align-center justify-between" style="height:auto!important; margin-top:0;">
                    <img src="@/assets/img/newIndex/icon.png" @click="$router.push('/')" alt="" class="icon" />
                    <div class="search flex" v-if="!showmenu">
                        <el-select size="mini" v-model="searchselect" placeholder="请选择">
                            <el-option label="职位" :value="1">职位</el-option>
                            <el-option label="公司" :value="2">公司</el-option>
                        </el-select>
                        <el-input style="width: 675px;" placeholder="请输入关键词" v-model="searchJobName" class="input" />
                        <!-- <div class="btn">
                        <img src="@/assets/img/newIndex/search.png" alt="" class="search-icon" />
                    </div> -->
                        <el-button  class="btn" @click="searchJob">
                            <i class="el-icon-search" style="font-size:20px"></i>
                        </el-button>
                    </div>
                    <div class="showmenu" v-if="showmenu">
                        <ul>
                            <li
                                v-for="(item, index) in navList"
                                :key="index"
                                class="li-item"
                                @click="openPage(index)"
                            >
                                {{ item.title }}
                            </li>
                        </ul>
                    </div>
                    <div class="topchange flex align-center justify-center" v-if="!showmenu" @click="showmenu=!showmenu">
                        <i class="el-icon-menu"></i> 菜单
                    </div>
                    <div class="topchange flex align-center justify-center" v-if="showmenu" @click="showmenu=!showmenu">
                        <i class="el-icon-search"></i> 搜索
                    </div>
                </div>
            </div>
        </div>
        <div class="fzbox flex align-center justify-center" v-show="showchange" @click="showchange=false">
            <div class="fzcont bg-white">
                <div class="fztitle flex align-center">
                    <strong>切换分站</strong>
                    <em><i class="el-icon-close"></i></em>
                </div>
                <div class="zzlist">
                    <span @click="changeOrg(null)">上饶总站</span>
                </div>
                <div class="fzlist">
                    <span v-for="item in orlist" @click="changeOrg(item)">{{item.districtName}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userRequest from '../../api/user';
export default {
    props: {
        currentIndex: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        currentIndex(val1, val2) {
            console.log('当前的索引', val1, val2);
            this.currentTab = val1;
        },
    },
    created() {
        let userInfo = window.localStorage.getItem('userInfo');
        userInfo && (this.userInfo = JSON.parse(userInfo));
        let roleId = Number(window.localStorage.getItem('roleId'));
        this.roleId = roleId;

        // let curorg=JSON.parse(localStorage.getItem("curorg"))
        // if(curorg){
        //     this.$store.commit("changeOrgid",curorg)
        // }else{
        //      this.$store.commit("changeOrgid",null)
        // }

        // let statetitle=this.$store.state.org.title
        // console.log(statetitle)
        // console.log(1111122)
        // let newtitle
        // if(statetitle=='广信区总站'){
        //     newtitle="首页"
        // }else{
        //     newtitle="首页"
        // }
        // this.$set(this.navList[0],"title",newtitle)

    },
    computed: {
        nowtitle () {
            return this.$store.state.org.title;　　//需要监听的数据
        }
    },
    mounted() {
        window.addEventListener('scroll',this.handleScroll,true)
        // this.getOrg()
    },
    data() {
        return {
            showsmallmenu:false,
            showmenu:false,
            showchange:false,
            orlist:[],
            searchJobName: "", //职位搜索
            searchselect:1,
            showewm: false,
            userInfo:null,
            roleId: null,
            currentTab: 0,
            navList: [
                { title: '首页', routerPath: '/user/index' },
                { title: '职位', routerPath: '/user/searchJobs' },
                { title: '企业', routerPath: '/user/companyList' },
                { title: '招聘会', routerPath: '/user/jobFair' },
                { title: '云招聘', routerPath: '/user/onlineJobFair' },
                { title: '资讯', routerPath: '/user/news' },
                { title: '事业单位招考', routerPath: 'zhaokao' },
                { title: '高校就业', routerPath: '/school/job' },
                { title: '共享用工', routerPath: '/shareEmp' },
            ],
            value: '职位',
            isChoice: true,
            hoverIdx: 1,
            tabon: 0,
        };
    },
    methods: {
        changeMenu(){
            let statetitle=this.$store.state.org.title
            let newtitle
            if(statetitle=='上饶总站'){
                newtitle="首页"
            }else{
                newtitle=statetitle+"分站"
            }
            console.log(newtitle)
            console.log(11111111)
            this.$set(this.navList[0],"title",newtitle)
        },
         handleScroll(){
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            // console.log(scrollTop);
            if(scrollTop>67){
                this.showsmallmenu=true
            }else{
                this.showsmallmenu=false
            }
        },
        changeOrg(row){
            this.$store.commit("changeOrgid",row)
            location.reload()
        },
        getOrg(){
            userRequest.orgList({id:240}).then(res=>{
                console.log(111)
                console.log(res)
                if(res.code==200){
                    this.orlist=res.data
                }
            })
        },
        searchJob() {
            if (!this.searchJobName) {
                this.$message.error("关键词不能为空~");
                return;
            }
            if(this.searchselect==1){
                this.$router.push({
                path: "/user/searchJobs",
                query: {
                    workName: this.searchJobName,
                },
                });
            }else if(this.searchselect==2){
                this.$router.push({
                path: "/user/companyList",
                query: {
                    key: this.searchJobName,
                },
                });
            }

        },
        openPage2(e){
            this.hoverIdx=1
            this.currentTab = 0;
            this.routeTo("/user/index");
        },
        openPage(index) {
            this.hoverIdx=index+1
            if (this.navList[index].routerPath === 'none') {
                //this.$message.success("功能开发中，敬请期待")
                this.$message({
                    message: '功能开发中，敬请期待',
                    type: 'success',
                    duration: 600,
                });
            }else if(this.navList[index].routerPath === 'zhaokao'){
                console.log(23)
                window.open("http://hrss.zgsr.gov.cn/hrss/klzp/zwgk_xxgklist.shtml",'_blank')
            }else if(this.navList[index].routerPath === 'dangan'){
                console.log(23)
                window.open("http://zwfw.rst.jiangxi.gov.cn:8081/jxrsdt/index.html",'_blank')
            }else if(this.navList[index].routerPath === 'gongxiang'){
                console.log(23)
                window.open("https://srrlzysc.com/#/user/show?id=12831",'_blank')
            } else {
                this.currentTab = index;
                this.routeTo(this.navList[index].routerPath);
            }
        },
        routeTo(path) {
            if (path === this.$route.path) {
                return;
            }
            this.$router.push({
                path: path,
            });
        },
        userLogout() {
            this.$confirm('是否确定退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    userRequest
                        .userLogout()
                        .then(res => {
                            if (res.code === 200) {
                                window.localStorage.setItem('userInfo', JSON.stringify(''));
                                window.localStorage.setItem('token', JSON.stringify(''));
                                this.$message.success('退出成功~');
                                this.$router.push({ path: '/' });
                            }
                        })
                        .catch(err => {
                            this.$message.error(err);
                        });
                    // this.$message({
                    //     type: 'success',
                    //     message: '删除成功!'
                    // })
                })
                .catch(() => {});
        },
        handleCommand(command) {
            //   this.$message("click on item " + command);
            this.$router.push({
                path: command,
            });
        },
    },
    watch:{
        nowtitle(newVal, oldVal){
            this.changeMenu()
        },
        $route(to,from){
            console.log(to.path);//到哪去
            if(to.path=='/user/index'){
                this.hoverIdx=1
                this.currentTab = 0;
            }else if(to.path=='/user/searchJobs'){
                this.hoverIdx=2
                this.currentTab = 1;
            }else if(to.path=='/user/companyList'){
                this.hoverIdx=3
                this.currentTab = 2;
            }else if(to.path=='/user/jobFair'){
                this.hoverIdx=4
                this.currentTab = 3;
            }else if(to.path=='/user/news'){
                this.hoverIdx=6
                this.currentTab = 5;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/global.scss';

.toppic img{ width: 100%;}
.topchange{ width: 170px; color:#1787fb; font-size: 18px; cursor: pointer;}
.showmenu ul{ display: flex;}
.showmenu ul li{ display: block; line-height: 56px; text-align: center; font-size: 16px; padding: 0 20px; height: 56px;}
.user-header2{ padding: 0px 0; width: 1200px; margin: 0 auto; }
.smallnavbox{position: fixed; top: 0; left: 0; padding-top: 10px; padding-bottom: 10px; box-shadow: rgba(0,0,0,0.1) 0 0 18px; width: 100%; z-index:2500; background: #ffffff;}
.fzbox{ position: fixed; width: 100%; height: 100vh; top: 0; left: 0; z-index: 10000; background: rgba(0,0,0,0.5);}
.fzcont{ width: 600px; overflow: hidden;   height: 270px; box-shadow: rgba(0,0,0,0.1) 0 0 18px; border-radius: 3px;}
.fzlist{ display: flex; padding: 20px; padding-top: 2px; padding-bottom: 30px; align-items: center; flex-wrap: wrap;}
.fzlist span{ cursor: pointer; margin-right: 10px; margin-bottom: 12px; display: inline-block; padding: 10px 15px; border: #e9e9e9 solid 1px;}
.fzlist span:hover{ box-shadow: rgba(0,0,0,0.05) 0 0 15px;}
.zzlist{padding: 20px; padding-bottom: 5px;}
.zzlist span{ cursor: pointer; margin-right: 10px; color:#ff704f; margin-bottom: 12px; display: inline-block; padding: 10px 15px; border: #ff704f solid 1px;}
.zzlist span:hover{ box-shadow: rgba(0,0,0,0.05) 0 0 15px;}


.fztitle{ padding: 0 15px; background: #298dff; height: 40px; color:#ffffff;}

.topmenubox{ width: 100%; background: #ffffff; border-bottom: #298dff solid 2px;}
.nav {
    background-color: #fff;
    max-width: 1200px;
    margin: 0 auto;
}
.nav .top-menu {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    height: 40px;
}
.nav .top-menu .left .city {
    margin-right: 10px;
}
.nav .top-menu .left .change {
    color: #1787fb;
}
.nav .top-menu .right .menu-icon {
    height: 16px;
    margin-right: 8px;
    width: 16px;
}

.nav .top-menu .right .menu-item {
    margin-right: 30px;
}
.nav .top-menu .right .menu-item.right0 {
    margin-right: 0;
}
.nav .top-menu .left .site {
    width: 12px;
    height: 12px;
}
.nav .search-bar {
    margin-top: 16px;
    height: 68px;
}
.nav .search-bar .icon {
    width: 137px;
    margin: auto 0;
    margin-right: 39px;
}
.nav .search-bar .search {
    /* border: 1px solid #; */
    height: 56px;
    margin: auto 0;
}
::v-deep .el-select .el-input__inner {
    width: 81px;
    border: 1px solid #ccc;
    height: 56px;
    border-radius: 0;
}

.nav .search-bar .search .select option {
    color: #333;
}
::v-deep .input .el-input__inner {
    border: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 0px;
    width: 675px;
    border-radius: 0;
    height: 56px;
}
.nav .search-bar .search .btn {
    background-color: #298dff;
    width: 98px;
    height: 56px;
    border-radius: 0;
    border: 1px solid #298dff;
    color: #fff;
}
.nav .search-bar .search .btn .search-icon {
    width: 22px;
    height: 22px;
    margin: 17px 38px;
}
.nav .choice-area {
    margin-left: 14px;
}
.nav .choice-area .choice-item {
    width: 114px;
    height: 17px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 17px;
    color: #666666;
    opacity: 1;
    margin-top: 8px;
    cursor: pointer;
    margin-bottom: 2px;
}
.nav .choice-area .choice-item.active {
    color: #1787fb;
    background: none;
}
.nav .choice-area .qr {
    position: relative;
    width: 60px;
    height: 60px;
    padding: 4px;
    background-color: #fff;
    border: 1px solid #e9eaed;
    margin-left: 14px;
}
.qr:before {
    content: '';
    position: absolute;
    top: 40%;
    right: -20px;
    border: 10px solid transparent;
    border-left-color: #fff;
}
.li-item {
    height: 46px;
    position: relative;
    cursor: pointer;


}
.li-item span{ display: block;  width: 100%; font-weight: bold; color: #222222; text-align: center; z-index: 2000;}
.li-item:after {
    content: '';
    position: relative;
    display: block;
    margin: 0 auto;
    width: 0;
    height: 46px;
    z-index: 0;
    top: 0px;
    left: 0;
    -webkit-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;

    background: #298dff;
    border-radius: 3px 3px 0 0;
}
.li-item.link span{
    color:#ffffff!important;


}
.li-item.link{ background: #298dff; border-radius: 3px 3px 0 0;}
.li-item.link:after {
    width: 100%;
}
.li-item:hover:after {
    width: 100%;
}
.menu ul {
    overflow: hidden;
    // display: flex;
    // justify-content: space-around;
}
.menu ul li {
    font-size: 16px;
    line-height: 50px;
    height: 100%;
    width: auto;
    float: left;
    height: 46px;
    line-height: 46px;
    text-align: center;
    position: relative;
    padding: 0 18px;
    margin-right: 25px;
    overflow: hidden;
}
.menu ul li:hover span{ color:#ffffff!important; }
.menu ul li:hover{ color:#ffffff!important; background: #298dff; border-radius: 3px 3px 0 0;}

.menu {
    margin-top: 14px;
    height: 46px;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    font-style: normal;
}
.tcewm {
    display: flex;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    position: absolute;
    width: 500px;
    height: 210px;
    background: #ffffff;
    left: -250px;
    top: 80px;
    box-shadow: #999 2px 2px 12px;
}
.tcewmitem {
}
.tcewmitem span {
    display: block;
    height: 130px;
    overflow: hidden;
}
.tcewmitem p {
    display: block;
    text-align: center;
    font-size: 14px;
    margin: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
}
.document-box {
    width: 100%;
    height: 80px;
    //   background-color: red;
}

.drop {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    .avata {
    display: flex;
    align-items: center;
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    }
    .company-name {
        margin-left: 10px;
        font-weight: 500;
        max-width: 100px;
        color: #000;
    }
    .el-icon-arrow-down {
        font-weight: bold;
        color: #000;
    }
    }
}

.showmenu .li-item::after{ display: none;}
.showmenu .li-item.link{color:#298dff!important;}
.qr {
    width: 78px;
    height: 78px;
}
@media screen and (max-width: 800px) {
    * {
        box-sizing: border-box;
    }
    .nav {
        display: none;
    }
    .user-header2 {
        display: block;
    }
    .document-box {
        display: none;
        //   background-color: red;
    }
}
</style>
