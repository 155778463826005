import layout from '../components/layout/Layout.vue'
import layout1 from '../components/layout1/Layout1.vue'
import layout2 from '../components/layout2/layout2.vue'
//定义业务页面
const routes = [
    {       // 管理员
        path: "/home",
        component: layout,
        children: [
            {
                path: "/home",
                name: "主页",
                component: () => import("@/views/Home.vue"),
            },
            ,
            {
                path: "/intention",
                name: "意向信息",
                component: () => import("@/views/intention/intention.vue"),
            },
            {
                path: "/offlinejob",
                name: "线下求职者",
                component: () => import("@/views/intention/offlinejob.vue"),
            },
            {
                path: "/offlinecompany",
                name: "线下企业",
                component: () => import("@/views/intention/offlinecompany.vue"),
            },
            {
                path: "/intention/personnum",
                name: "服务人次",
                component: () => import("@/views/intention/personnum.vue"),
            },
            {
                path: "/booth",
                name: "展分分配方案",
                component: () => import("@/views/booth/Index.vue"),
            },
            {
                path: "/booth/history",
                name: "方案历史",
                component: () => import("@/views/booth/History.vue"),
            },

            {
                path: "/jobfair/index",
                name: "招聘会",
                component: () => import("@/views/jobfair/Index.vue"),
            }
            ,
            {
                path: "/jobfair/history",
                name: "招聘会历史",
                component: () => import("@/views/jobfair/History.vue"),
            }
            ,
            {
                path: "/jobfair/add",
                name: "添加招聘会",
                component: () => import("@/views/jobfair/Add.vue"),
            },
            {
                path: "/school",
                name: "学校管理",
                component: () => import("@/views/school/school.vue"),
            },
            {
                path: "/member/addMember",
                name: "添加会员",
                component: () => import("@/views/member/addMember.vue"),
            },
            {
                path: "/member/memberList",
                name: "角色列表",
                component: () => import("@/views/member/memberList.vue"),
            },
            {
                path: "/laborPersonnel/laborPersonnel",
                name: "劳动人员数据",
                component: () => import("@/views/laborPersonnel/laborPersonnel.vue"),
            },
            {
                path: "/permission/permissionManage",
                name: "权限管理",
                component: () => import("@/views/permission/permissionManage.vue"),
            },
            {
                path: "/user/amdinManage",
                name: "管理员列表",
                component: () => import("@/views/user/amdinManage.vue"),
            },
            {
                path: "/user/companyManage",
                name: "企业管理",
                component: () => import("@/views/user/companyManage.vue"),
            },
            {
                path: "/user/userManage",
                name: "用户管理",
                component: () => import("@/views/user/userManage.vue"),
            },
            {
                path: "/user/checkin",
                name: "报到证预约",
                component: () => import("@/views/user/checkin.vue"),
            },
            {
                path: "/user/recruituse",
                name: "企业用工情况",
                component: () => import("@/views/user/recruituse.vue"),
            },
            {
                path: "/user/userCenter",
                name: "个人中心",
                component: () => import("@/views/user/userCenter.vue"),
            },
            {
                path: "/jobfair/edit",
                name: "编辑招聘会",
                component: () => import("@/views/jobfair/Edit.vue"),
            },
            {
                path: "/jobfair/show",
                name: "招聘会详情",
                component: () => import("@/views/jobfair/Show.vue"),
            },
            {
                path: "/resume/index",
                name: "普通人才库",
                component: () => import("@/views/resume/Index.vue"),
            },
            {
                path: "/datareport/index",
                name: "数据报表",
                component: () => import("@/views/datareport/datareport.vue"),
            },
            {
                path: "/datareport/show",
                name: "数据报表详情",
                component: () => import("@/views/datareport/show.vue"),
            },
            {
                path: "/resume/oddjob",
                name: "零工库",
                component: () => import("@/views/resume/oddjob.vue"),
            },
            {
                path: "/resume/show",
                name: "简历详情",
                component: () => import("@/views/resume/Show.vue"),
            },
            {
                path: "/tongji/index",
                name: "统计",
                component: () => import("@/views/tongji/index.vue"),
            },
            {
                path: "/keyenterprise/keyenterprise",
                name: "各分站数据统计",
                component: () => import("@/views/keyenterprise/keyenterprise.vue"),
            },
            {
                path: "/information/class",
                name: "信息分类",
                component: () => import("@/views/information/Inclass.vue"),
            },
            {
                path: "/information/index",
                name: "信息资讯",
                component: () => import("@/views/information/Index.vue"),
            },
            {
                path: "/information/add",
                name: "添加信息",
                component: () => import("@/views/information/Add.vue"),
            },
            {
                path: "/information/edit",
                name: "添加信息",
                component: () => import("@/views/information/Edit.vue"),
            },
            {
                path: "/position/index",
                name: "职位列表管理",
                component: () => import("@/views/position/index.vue"),
            },
            {
                path: "/user/legalManage",
                name: "招聘负责人列表管理",
                component: () => import("@/views/user/legalManage.vue"),
            },
            {
                path: "/position/industry",
                name: "行业管理",
                component: () => import("@/views/position/industry.vue"),
            },
            {
                path: "/position/screen",
                name: "大屏职位",
                component: () => import("@/views/position/bigscreen.vue"),
            },
            {
                path: "/position/shareEmp",
                name: "共享用工",
                component: () => import("@/views/position/shareEmp.vue"),
            },
            {
                path: "/position/jobLabel",
                name: "职位标签",
                component: () => import("@/views/position/jobLabel.vue"),
            },
            {
                path: "/bigdata/resume",
                name: "人才简历库",
                component: () => import("@/views/bigdata/resume.vue"),
            },
            {
                path: "/bigdata/supply",
                name: "供需关系数据",
                component: () => import("@/views/bigdata/supply.vue"),
            },
            {
                path: "/bigdata/industry",
                name: "产业供需分析",
                component: () => import("@/views/bigdata/industry.vue"),
            },
            {
                path: "/bigdata/springaction",
                name: "春风行动",
                component: () => import("@/views/bigdata/springaction.vue"),
            },
            {
                path: "/bigdata/enterprises",
                name: "重点企业数据",
                component: () => import("@/views/bigdata/enterprises.vue"),
            },
            {
                path: "/bigdata/personnel",
                name: "上饶人才大数据",
                component: () => import("@/views/bigdata/personnel.vue"),
            },
            {
                path: "/bigdata/position",
                name: "职位热度薪资排行榜",
                component: () => import("@/views/bigdata/position.vue"),
            },
            {
                path: "/bigdata/flow",
                name: "职位热度薪资排行榜",
                component: () => import("@/views/bigdata/flow.vue"),
            },
            {
                path: "/colleges/colleges",
                name: "高校",
                component: () => import("@/views/colleges/colleges.vue"),
            },
            {
                path: "/colleges/class",
                name: "高校新闻分类",
                component: () => import("@/views/colleges/class.vue"),
            },
            {
                path: "/colleges/news",
                name: "高校新闻",
                component: () => import("@/views/colleges/news.vue"),
            },
            {
                path: "/colleges/course",
                name: "课程列表",
                component: () => import("@/views/colleges/course.vue"),
            },
            {
                path: "/hotPatch",
                name: "hotPatch",
                meta: {
                    title: '热更新'
                },
                component: () => import("@/views/hotPatch/Index.vue"),
            },
            {
                path: "/IntegralManagement",
                name: "IntegralManagement",
                meta: {
                    title: '积分管理'
                },
                component: () => import("@/views/IntegralManagement/Index.vue"),
            },
            {
                path: "/advertising/slideShow",
                name: "slideShow",
                meta: {
                    title: '轮播图管理'
                },
                component: () => import("@/views/advertising/slideshow.vue"),
            },
        ]
    }, {
        path: '/company',
        component: layout1,
        name: 'company',
        children: [
            {
                path: '/company/postJob',
                name: 'postJob',
                component: () => import("@/pages/recruitment/postJob/Index.vue"),
                meta: {
                    title: '发布职位'
                }
            },
            {
                path: '/company/resumeCenter',
                name: 'resumeCenter',
                component: () => import("@/pages/recruitment/resumeCenter/Index.vue"),
                meta: {
                    title: '简历中心'
                }
            },
            {
                path: '/company/message',
                name: 'resumeCenter',
                component: () => import("@/pages/recruitment/message/index.vue"),
                meta: {
                    title: '消息列表'
                }
            },
            {
                path: '/company/datareport',
                name: 'resumeCenter',
                component: () => import("@/pages/recruitment/datareport/datareport.vue"),
                meta: {
                    title: '数据上报'
                }
            },
            {
                path: '/company/datalist',
                name: 'resumeCenter',
                component: () => import("@/pages/recruitment/datareport/list.vue"),
                meta: {
                    title: '数据上报列表'
                }
            },
            {
                path: '/company/messageshow',
                name: 'resumeCenter',
                component: () => import("@/pages/recruitment/message/show.vue"),
                meta: {
                    title: '消息详情'
                }
            },
            {
                path: '/company/notice',
                name: 'notice',
                component: () => import("@/pages/recruitment/notice/Index.vue"),
                meta: {
                    title: '招聘会预约'
                }
            },
            {
                path: '/company/bookingHistory',
                name: 'bookingHistory',
                component: () => import("@/pages/recruitment/bookingHistory/Index.vue"),
                meta: {
                    title: '招聘会预约历史'
                }
            },
            {
                path: '/company/orderBooth',
                name: 'orderBooth',
                component: () => import("@/pages/recruitment/orderBooth/Index.vue"),
                meta: {
                    title: '在线预定'
                }
            },
            {
                path: '/company/downloadResume',
                name: 'downloadResume',
                component: () => import("@/pages/recruitment/downloadResume/Index.vue"),
                meta: {
                    title: '下载的简历'
                }
            },
            {
                path: '/company/video',
                name: 'collection',
                component: () => import("@/pages/recruitment/video/index.vue"),
                meta: {
                    title: '视频面试'
                },
                children: [
                    {
                        path: "/company/videohome",
                        component: () => import("@/pages/recruitment/home-video/index.vue"),
                    },
                    {
                        path: "/company/video-call",
                        component: () => import("@/pages/recruitment/video-call/index.vue"),
                    },
                ]
            },
            {
                path: '/company/collection',
                name: 'collection',
                component: () => import("@/pages/recruitment/collection/Index.vue"),
                meta: {
                    title: '收藏的简历'
                }
            },
            {
                path: '/company/resumeInfo',
                name: 'resumeInfo',
                component: () => import("@/pages/recruitment/resumeInfo/Index.vue"),
                meta: {
                    title: '详细简历'
                }
            },
            {
                path: '/company/jobManagement',
                name: 'resumeInfo',
                component: () => import("@/pages/recruitment/jobManagement/Index.vue"),
                meta: {
                    title: '职位管理'
                }
            },
            {
                path: '/company/mine',
                name: 'mine',
                component: () => import("@/pages/recruitment/mine/Index.vue"),
                meta: {
                    title: '我的'
                }
            },
            {
                path: '/company/certification',
                name: 'certification',
                component: () => import("@/pages/recruitment/certification/Index.vue"),
                meta: {
                    title: '企业认证'
                }
            },
            {
                path: '/company/companyInfo',
                name: 'companyInfo',
                component: () => import("@/pages/recruitment/companyInfo/Index.vue"),
                meta: {
                    title: '基本资料'
                }
            },
            {
                path: '/company/settings',
                name: 'settings',
                component: () => import("@/pages/recruitment/settings/Index.vue"),
                meta: {
                    title: '账号设置'
                }
            },
            {
                path: '/company/resetPassword',
                name: 'resetPassword',
                component: () => import("@/pages/recruitment/resetPassword/Index.vue"),
                meta: {
                    title: '找回密码'
                }
            },
            {
                path: '/company/integralRecord',
                name: 'resetPassword',
                component: () => import("@/pages/recruitment/integralRecord/Index.vue"),
                meta: {
                    title: '积分明细'
                }
            },
            {
                path: '/company/effect',
                name: 'effect',
                component: () => import("@/pages/recruitment/effect/Index.vue"),
                meta: {
                    title: '招聘效果'
                }
            },
            {
                path: '/company/faceRecord',
                name: 'faceRecord',
                component: () => import("@/pages/recruitment/faceRecord/Index.vue"),
                meta: {
                    title: '面邀记录'
                }
            },
            {
                path: '/company/blank',
                name: 'blank',
                component: () => import("@/pages/recruitment/blank/Index.vue"),
                meta: {
                    title: '空白页'
                }
            },
        ]
    },
    {  // 用户端
        path: '/user',
        component: layout2,
        name: 'user',
        children: [
            {
                path: '/user/index',
                name: 'userIndex',
                component: () => import("@/pages/users/index/newIndex.vue"),
                meta: {
                    title: '首页'
                }
            },

            {
                path: '/user/jobFair',
                name: 'jobFairIndex',
                component: () => import("@/pages/users/jobFair/index.vue"),
                meta: {
                    title: '招聘会'
                }
            },
            {
                path: '/user/changeweb',
                name: 'changeweb',
                component: () => import("@/pages/users/changeWeb/changeweb.vue"),
                meta: {
                    title: '分站切换'
                }
            },
            {
                path: '/school/job',
                name: 'schooljob',
                component: () => import("@/pages/users/school/school.vue"),
                meta: {
                    title: '高校就业'
                }
            },
            {
                path: '/school/yuyue',
                name: 'schoolyuyue',
                component: () => import("@/pages/users/school/yuyue.vue"),
                meta: {
                    title: '报到证预约'
                }
            },
            {
                path: '/tempCompanyList',
                name: 'tempCompanyList',
                component: () => import("@/pages/users/companyList/tempCompanyList.vue"),
                meta: {
                    title: '乡镇企业'
                }
            },
            {
                path: '/school/result',
                name: 'schoolresult',
                component: () => import("@/pages/users/school/result.vue"),
                meta: {
                    title: '报到证预约查询'
                }
            },
            {
                path: '/school/news',
                name: 'schoolnews',
                component: () => import("@/pages/users/school/news.vue"),
                meta: {
                    title: '高校新闻'
                }
            },
            {
                path: '/school/zhidao',
                name: 'schoolzhidao',
                component: () => import("@/pages/users/school/zhidao.vue"),
                meta: {
                    title: '高校指导'
                }
            },
            {
                path: '/school/showzd',
                name: 'schoolshowzd',
                component: () => import("@/pages/users/school/showzd.vue"),
                meta: {
                    title: '指导详情'
                }
            },
            {
                path: '/school/show',
                name: 'schoolshow',
                component: () => import("@/pages/users/school/show.vue"),
                meta: {
                    title: '高校新闻'
                }
            },
            {
                path: '/user/news',
                name: 'jobFairIndex',
                component: () => import("@/pages/users/news/index.vue"),
                meta: {
                    title: '资讯列表'
                }
            },
            {
                path: '/user/jkq',
                name: 'jobFairIndex',
                component: () => import("@/pages/users/news/jkq.vue"),
                meta: {
                    title: '经开区招聘'
                }
            },
            {
                path: '/user/show',
                name: 'jobFairIndex',
                component: () => import("@/pages/users/news/show.vue"),
                meta: {
                    title: '资讯详情'
                }
            },
            {
                path: '/user/jobFair-desc',
                name: 'jobFairDesc',
                component: () => import("@/pages/users/jobFair/Info.vue"),
                meta: {
                    title: '招聘会信息'
                }
            },
            {
                path: '/user/onlineJobFair',
                name: 'jobFairIndex',
                component: () => import("@/pages/users/onlineJobFair/index.vue"),
                meta: {
                    title: '网络招聘会'
                }
            },
            {
                path: '/user/sendlist',
                name: 'jobFairIndex',
                component: () => import("@/pages/users/message/list.vue"),
                meta: {
                    title: '消息管理'
                }
            },
            {
                path: '/user/smartjob',
                name: 'smartJob',
                component: () => import("@/pages/users/smartJob/smartJob.vue"),
                meta: {
                    title: '消息管理'
                }
            },
            {
                path: '/user/delivery',
                name: 'smartJob',
                component: () => import("@/pages/users/delivery/delivery.vue"),
                meta: {
                    title: '投递记录'
                }
            },
            {
                path: '/user/interview',
                name: 'smartJob',
                component: () => import("@/pages/users/interview/interview.vue"),
                meta: {
                    title: '面试记录'
                }
            },
            {
                path: '/user/sendshow',
                name: 'jobFairIndex',
                component: () => import("@/pages/users/message/show.vue"),
                meta: {
                    title: '消息展示'
                }
            },
            {
                path: '/user/video',
                name: 'collection',
                component: () => import("@/pages/users/video/index.vue"),
                meta: {
                    title: '视频面试'
                },
                children: [
                    {
                        path: "/user/videohome",
                        component: () => import("@/pages/users/home-video/index.vue"),
                    },
                    {
                        path: "/user/video-call",
                        component: () => import("@/pages/users/video-call/index.vue"),
                    },
                ]
            },
            {
                path: '/user/onlineJobFairDetails',
                name: 'jobFairIndex',
                component: () => import("@/pages/users/onlineJobFair/details.vue"),
                meta: {
                    title: '网络招聘会详情'
                }
            },
            {
                path: '/user/searchJobs',
                name: 'searchJobs',
                component: () => import("@/pages/users/searchJobs/Index.vue"),
                meta: {
                    title: '职位'
                }
            },
            {
                path: '/user/newJobList',
                name: 'newJobList',
                component: () => import("@/pages/users/jobList/newJobList.vue"),
                meta: {
                    title: '热门职位'
                }
            },
            {
                path: '/user/companyList',
                name: 'companyList',
                component: () => import("@/pages/users/companyList/Index.vue"),
                meta: {
                    title: '企业列表'
                }
            },{
                path: '/shareEmp',
                name: 'shareEmp',
                component: () => import("@/pages/users/shareEmp/shareEmp.vue"),
                meta: {
                    title: '共享用工'
                }
            },
            {
                path: '/user/notice/articleDetails',
                name: 'articleDetails',
                component: () => import("@/pages/users/notice/articleDetails.vue"),
                meta: {
                    title: '政策详情'
                }
            },

            {
                path: '/user/companyNews',
                name: 'companyNews',
                component: () => import("@/pages/users/companyNews/Index.vue"),
                meta: {
                    title: '企业信息'
                },
                redirect: '/user/companyNews/gongsi',
                children: [
                    {
                        path: '/user/companyNews/gongsi',
                        name: 'companyGongsi',
                        component: () => import("@/pages/users/companyNews/Gongsi.vue"),
                    },
                    {
                        path: '/user/companyNews/cJobs',
                        name: 'companyCJobs',
                        component: () => import("@/pages/users/companyNews/Cjobs.vue"),
                    },

                ]
            },
            {
                path: '/user/companyJobs',
                name: 'companyJobs',
                component: () => import("@/pages/users/companyNews/companyJobs.vue"),
            },
            {
                path: '/user/jobDetail',
                name: 'jobDetail',
                component: () => import("@/pages/users/jobDetail/Index.vue"),
                meta: {
                    title: '职位信息'
                }
            },
            {
                path: '/user/u_blank',
                name: 'userBlank',
                component: () => import("@/pages/users/userBlank/Index.vue"),
                meta: {
                    title: '职位信息'
                }
            },
            {
                path: '/user/settingInfo',
                name: 'settingInfo',
                component: () => import("@/pages/users/settingInfo/Index.vue"),
                meta: {
                    title: '个人设置'
                },
                redirect: '/user/settingInfo/userInfo',
                children: [
                    {
                        path: '/user/settingInfo/userInfo',
                        name: 'settingInfo',
                        component: () => import("@/pages/users/settingInfo/UserInfo.vue"),
                    },
                    {
                        path: '/user/settingInfo/editUserPassword',
                        name: 'editUserPassword',
                        component: () => import("@/pages/users/settingInfo/EditUserPassword.vue"),
                    },
                ]
            },
            {
                path: '/user/resumes',
                name: 'userResume',
                component: () => import("@/pages/users/resume/Index.vue"),
                meta: {
                    title: '个人简历'
                }
            },
            {
                path: '/user/realname',
                name: 'realname',
                component: () => import("@/pages/users/resume/realname.vue"),
                meta: {
                    title: '名实认证'
                }
            },
            {
                path: '/user/creatResume',
                name: 'creatResume',
                component: () => import("@/pages/users/resume/CreatResume.vue"),
                meta: {
                    title: '创建简历'
                }
            },
            {
                path: '/user/deliveryRecord',
                name: 'deliveryRecord',
                component: () => import("@/pages/users/deliveryRecord/Index.vue"),
                meta: {
                    title: '投递记录'
                }
            },

        ]
    },
    {
        path: '/user/tempIndex',
        name: 'tempIndex',
        component: () => import("@/pages/users/index/tempIndex.vue"),
        meta: {
            title: '首页'
        }
    },
    {
        path: '/user/tempInterview',
        name: 'tempIndex',
        component: () => import("@/pages/users/index/tempInterview.vue"),
        meta: {
            title: '在线面试'
        }
    },
    {
        path: '/user/public/register',
        name: 'userRegister',
        component: () => import("@/pages/users/public/register.vue"),
        meta: {
            title: '用户注册'
        }
    },
    {
        path: '/user/jobList',
        name: 'userRegister',
        component: () => import("@/pages/users/jobList/index.vue"),
        meta: {
            title: '职位列表轮播'
        }
    }
]
export default routes
