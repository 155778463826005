<template>
   <div class="home-container">
       <!-- 头部 -->
       <company-header :componyName="componyName"></company-header>
       <!-- 内容区 -->
       <el-container>
           <!-- 侧边栏 -->
           <el-aside>
               <company-menu :activePath="activePath"></company-menu>
           </el-aside>
           <el-main>
               <router-view />
           </el-main>
       </el-container>
    <div class="masker" v-if="reviewState!==3">
        <div class="tips"></div>
    </div>
   </div>
</template>
<script>
import CompanyHeader from './CompanyHeader.vue'
import CompanyMenu from './CompanyMenu.vue'
import companyRequest from '../../api/company'
import{mapState} from 'vuex'
export default {
    created() {
        // //企业认证状态失败时，重新认证
        // if (window.localStorage.getItem('authInfoStatus') == 0 && this.$route.path !== '/company/certification') {
        //     // this.$message.warning('认证失败，请重新认证~')
        //     this.$router.push({
        //         path: '/company/certification'
        //     }).catch(()=> {})
        //     return
        // } else if (window.localStorage.getItem('authInfoStatus') == 2 && this.$route.path !== '/company/certification') {
        //     this.$message.warning('认证失败，请重新认证~')
        //     this.$router.push({
        //         path: '/company/certification'
        //     }).catch(()=> {})
        //     return
        // } else if (window.localStorage.getItem('memberBaseInfoId') == 0 && this.$route.path !== '/company/companyInfo') {
        //     this.$message.warning('基本资料尚未完善，请先填写基本信息~')
        //      this.$router.push({
        //         path: '/company/companyInfo'
        //     }).catch(()=> {})
        //     return
        // }
        if (window.localStorage.getItem('authInfoStatus') == 0 || window.localStorage.getItem('authInfoStatus') == 2) {
            // this.$message.warning('认证失败，请重新认证~')
            this.activePath = '/company/mine'
            this.$router.push({
                path: '/company/certification'
            }).catch(()=> {})
            return
        }
        if (window.localStorage.getItem('memberBaseInfoId') == 0) {
            //  this.$message.warning('基本资料尚未完善，请先填写基本信息~')
            this.activePath = '/company/companyInfo'
             this.$router.push({
                path: '/company/companyInfo'
            }).catch(()=> {})
            return
        }
        // console.log('this.$route', this.$route)
        if (this.$route.path === '/company/orderBooth') {
            this.activePath = '/company/notice'
            // console.log('this.activePath', this.activePath)
        } else if (this.$route.path === '/company/resumeInfo') {
            this.activePath = '/company/resumeCenter'
        } else if (this.$route.path === '/company/certification') {
            this.activePath = '/company/mine'
        } else if(this.$route.path === '/company/resetPassword') {
            this.activePath = '/company/settings'
        } else {
            this.activePath = this.$route.path
        }
        
    },
    mounted() {
        this.timer = setInterval(() => {
            // console.log('获取认证状态')
            companyRequest.getAuthStatus().then((res) => {
                if (res.code === 200) {
                    window.localStorage.setItem('authInfoStatus', res.data)
                }
            })
        }, 100000)
    },
    computed: {
        ...mapState(['storeActivePath'])
    },
    beforeDestroy() {
        if(this.timer) {
    　　　　clearInterval(this.timer); //关闭
    　　 }
    },
    components: {
        CompanyHeader,
        CompanyMenu
    },
    data() {
        return {
            seach: '',
            componyName: '趣星传媒有限公司', //公司名称
            activePath: '', //路由路径
            reviewState: 3, //企业认证状态
            timer: null //路由器
        }
    },
    watch: {
        $route(to, from) {
            // console.log('更新路由', to.path)
             //企业认证状态失败时，重新认证
             if (window.localStorage.getItem('authInfoStatus') == 0 || window.localStorage.getItem('authInfoStatus') == 2) {
                // this.$message.warning('认证失败，请重新认证~')
                this.activePath = '/company/mine'
                this.$router.push({
                    path: '/company/certification'
                }).catch(()=> {})
                return
            }
            if (window.localStorage.getItem('memberBaseInfoId') == 0) {
                // this.$message.warning('基本资料尚未完善，请先填写基本信息~')
                this.activePath = '/company/companyInfo'
                this.$router.replace({
                    path: '/company/companyInfo'
                }).catch(()=> {})
                return
            }
            // if (window.localStorage.getItem('authInfoStatus') == 2 && to.path !== '/company/certification') {
            //     this.$message.warning('认证失败，请重新认证~')
            //     this.$router.replace({
            //         path: '/company/certification'
            //     }).catch(()=> {})
            //     setTimeout(() => {
            //         this.activePath = '/company/mine'
            //         console.log('认证失败路由状态', this.activePath)
            //     })
            //     return
            // }
            // if (window.localStorage.getItem('memberBaseInfoId') == 0 && this.$route.path !== '/company/companyInfo') {
            //     this.$message.warning('基本资料尚未完善，请先填写基本信息~')
            //     this.$router.replace({
            //         path: '/company/companyInfo'
            //     }).catch(()=> {})
            //     return
            // }
            if (to.path === '/company/orderBooth') { //预约招聘会
            this.activePath = '/company/notice'
            // console.log('this.activePath', this.activePath)
            } else if (to.path === '/company/resumeInfo') {  //简历详情
                this.activePath = '/company/resumeCenter'
            } else if (to.path === '/company/certification') { //认证中心
                this.activePath = '/company/mine'
            } else if(this.$route.path === '/company/resetPassword') {
                this.activePath = '/company/settings'
            } else {
                this.activePath = to.path
            }
        },
        storeActivePath(val) {
            // console.log('storeActivePath', val)
            this.activePath = val
            // console.log('activePath', this.activePath)
        }
    }
}
</script>
<style lang="scss">
    @import '../../assets/css/global.scss';
    // @import '../../element-variables.scss';
</style>
<style lang="scss" scope>
    .home-container{
        position: relative;
        height: 100%;
        background: rgba(0, 0, 0, .05);
        .el-container{
        height: calc(100% - 100px);;
        }
        .el-menu-item{
            color: #bbb !important;
        }
        header{
            background: #fff !important;
        }
        .el-header{
            display: flex;
            height: 84px !important;
            align-items: center;

        }
        .el-aside{
            background: #383d4a;
            width: 200px !important;
            .el-menu {
                border-right: none;
            }
        }
        .masker{
            position: fixed;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .3);
            left: 0;
            top: 0;
            z-index: 100;
            .tips{
                position: absolute;
                width: 100%;
                height: 80px;
                background: goldenrod;
                bottom: 0;
                left: 0;
            }
        }
    }
    
    
</style>