<template>
    <div>
        <div class="topleft">
            <span class="closemenu" @click="changeMenu">
                <i :class="icon"></i>
            </span>
        </div>
        <div class="topright flex align-center">
<!--            <div class="margin-right-sm" v-if="userInfo.roleId==1">-->
<!--                <el-dropdown v-if="userInfo.orgId==0">-->
<!--                    <span class="el-dropdown-link">-->
<!--                        {{$store.state.org.title}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--                    </span>-->
<!--                    <el-dropdown-menu slot="dropdown">-->
<!--                        <el-dropdown-item @click.native="changeOrg(null)" >上饶总站</el-dropdown-item>-->
<!--                        <el-dropdown-item @click.native="changeOrg(item)" v-for="item in orlist">{{item.districtName}}</el-dropdown-item>-->
<!--                    </el-dropdown-menu>-->
<!--                </el-dropdown>-->
<!--                <span v-else>-->
<!--                    {{$store.state.org.title}}后台管理分站-->
<!--                </span>-->
<!--            </div>-->
            <em>{{userInfo.userName}}</em>
            <el-dropdown style="vertical-align: middle;">
                <span class="el-dropdown-link">
                    <el-avatar size="medium" :src="userInfo.headImg"></el-avatar>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <div @click="userCenter">
                        <el-dropdown-item><span>个人中心</span></el-dropdown-item>
                    </div>
                    <div @click="logOut">
                        <el-dropdown-item><span >退出登录</span></el-dropdown-item>
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import _api from '@/api/index'
import apiUrl from '@/api/public'
export default {
    data(){
        return{
            isclose:false,
            icon:"el-icon-s-fold",
            userInfo:{},
            orlist:[]
        }
    },
    created(){
        let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
        this.orlist = JSON.parse(window.localStorage.getItem('org'))
        this.userInfo = userInfo
        let curorg=JSON.parse(localStorage.getItem("curorg"))
        if(curorg){
            this.$store.commit("changeOrgid",curorg)
        }else{
             this.$store.commit("changeOrgid",null)
        }
    },
    methods:{
        changeOrg(row){
            this.$store.commit("changeOrgid",row)
            location.reload();
        },
        changeMenu(){
            console.log(55)
            if(!this.isclose){
                this.$emit("getchange",true)
                this.icon="el-icon-s-unfold"
                this.isclose=true
            }else{
                this.$emit("getchange",false)
                this.icon="el-icon-s-fold"
                this.isclose=false
            }

        },
        logOut(){
            _api.get(apiUrl.logOut,{}).then(res=>{
                if (res.code === 200) {
                    this.$message.success('成功退出登录')
                    window.sessionStorage.setItem('adminList',JSON.stringify(""))  // 权限列表
                    window.localStorage.setItem('userInfo', JSON.stringify("")) // 用户信息
                    window.localStorage.setItem('token', JSON.stringify(""))  // token
                    window.localStorage.setItem('curorg', null)  // token
                    this.$router.push({path:'/newAdminLogin'})
                }
            }).catch(err =>{
                console.log(2,err)
            })
        },
        userCenter(){
            this.$router.push({path:'/user/userCenter'})
        },
    }
}
</script>
<style scoped>
.topleft{ float: left; padding-top: 12px;}
.closemenu{ display: inline-block; width: 40px; height: 40px; cursor: pointer; text-align: center; line-height: 40px;}
.closemenu i{ font-size: 24px; color: #555555;}
.topright{ float: right; padding-top: 12px;}
.topright em{font-size: 14px; font-style: normal; vertical-align: middle; margin-right:15px; color: #555555;}
</style>
