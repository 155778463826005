<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {},
  data() {
    return {};
  },
};
</script>
<style>
@import "./assets/css/main.css";
</style>
<style lang="scss">
@import "./assets/css/rest.css";
ul,
li {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  min-width: 1360px;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media screen and (max-width: 800px) {
  *{
    box-sizing: border-box;
  }
  #app {
    min-width: 0px;
    height: 100%;
  }
}
</style>
