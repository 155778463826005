import request from './index'

//import userRequest from '@/api/user'

const api = {
    userLogin: '/app/userLogin/login', //用户登录
    userRegister: 'app/userLogin/register',   // 用户注册
    sendPhoneCode: '/sms/sendAppRegSms',  // 发送验证码，用于注册账号
    sendPhoneCodeForPassword:'/sms/sendAppForGetCode',  // 发送验证码，用于忘记密码
    resetPassword:'app/userLogin/forgetPassword', // 重置账户密码
    setRealName: '/app/user/makeIdNumber', // 实名认证
    setUserInfo: '/app/user/makeUserInfo', // 配置用户信息
    userLogout: '/app/userLogOut/logOut', //退出登录
    getAllIndustry: '/app/work/industry/selectAll', //查询所有行业
    getIndexData: '/app/user/website', //获取首页数据
    getJobList: '/app/work/queryWork', //获取搜索职位列表
    getJobDetail: '/app/work/selectById', //获取职位详情
    getLngLatByAddress: '/app/user/getLngLatByAddress', //获取经纬度
    getCompanyList: '/app/member/queryMember', //获取企业列表
    getNatureList: '/app/work/nature/selectAll', //获取公司性质
    getIndustryList: '/app/work/industry/selectAllCompany', //获取公司行业
    getCompanyDetail: '/app/member/selectMemberById', //获取公司信息
    getJobListByCompanyId: '/app/work/selectMyWorkPage', //获取公司对应职位信息
    getUserResumeList: 'app/resumeController/queryResumeByUserId', //获取用户所有简历
    getResumeInfo: '/app/resumeController/queryByResumeId', //获取用户简历详情
    getUserJobFairList: '/app/job/info', //获取招聘会列表
    getOnlineJobFairDetails:'/app/job/detail', // 获取网络招聘会的详情
    getUserJobFairInfo: '/jobFair/detailInfo', //获取招聘会详情
    setDefaultResume: '/app/resumeController/updateResumeDefault',  // 设置默认简历
    getWorkList: '/app/work/selectWork',  // 获取滚动的职位列表
    getUserInfo: '/app/user/selectUserInfo',  // 获取用户的基本信息
    createResumeBase: '/app/resumeController/appAddResume', //  创建简历的基本信息
    deleteResume: '/app/resumeController/deleteResumeById',  // 删除一份系统内填写的简历
    updateResume: '/app/resumeController/appUpdateResume', // 更新简历的基本信息
    uploadResumeUrl: '/app/user/upLoadResume',  // 上传附件简历的地址
    postResume:'/app/userResume/addCompanyResume',  // 投递简历
    collectionResume:'/app/collectionWork/collectionWork',  // 收藏简历
    resumeAddWork: '/app/resumeController/addResumeWorkExperience',  // 简历添加工作经历
    resumeUpdateWork: '/app/resumeController/updateResumeWorkExperience',  // 简历更新工作经历
    resumeAddEducation: '/app/resumeController/addResumeEductionExperience', // 添加教育经历
    resumeUpdateEducation: '/app/resumeController/updateResumeEductionExperience', // 更新教育经历
    resumeAddProject:'/app/resumeController/addResumeProjectExperience', // 简历增加项目经历
    resumeUpdateProject:'/app/resumeController/updateResumeProjectExperience', // 简历更新项目经历
    resumeDeleteSection: '/app/resumeController/deleteResumeInfo',  // 简历删除经历片段
    newsList: '/app/user/newsList',  // 根据分类id获取资讯列表首页
    getHomeNewList: '/app/user/getHomeNewList',  // 首页新闻
    industryClass: '/app/user/industryClass',  // 行业分类
    classificationList: '/app/user/classificationList',  // 分类列表
    newsById: '/app/user/newsById',  // 资讯详情
    sendMessage: '/message/insert',  // 添加消息
    listByPid: '/message/listByPid',  // 消息大的列表
    messageList: '/message/list',  // 消息小的列表
    forgetpassword: '/app/userLogin/forgetPassword',  // 消息小的列表
    school: '/school/queryHomeList',  // 高校
    district: '/district/list',//返回所有县区
    getNewWork: '/notLogin/getNewWork',//高校首页职位
    forward: '/app/forward',//报到证预约
    getLaseByToken: '/app/forward/getLaseByToken',//查询是否有报到证
    forwardtime: '/app/forward/time',//报到证预约时间
    schoolNewsindex: '/app/user/schoolNews',//高校首页新闻
    curriculumindex: '/app/user/curriculum',//高校首页指导
    newsList2: '/app/user/schoolNewsList',//高校新闻
    schoolClass: '/app/user/schoolClass',//高校新闻分类
    schoolAll: '/app/user/schoolAll',//全部高校
    curriculumDetail: '/app/user/curriculumDetail',//指导详情
    homeQueryWork: '/app/work/homeQueryWork',//查询相关的职位
    queryUserHomeNumber: '/app/user/queryUserHomeNumber',//个人登录后统计投递数
    getIsHaveNotRed: '/message/getIsHaveNotRed',//判断是否有未讯消息
    queryMyDelivery: '/app/userResume/queryMyDelivery',//投递记录
    queryMyInterview: '/app/interview/queryMyInterview',//面试记录
}
const userRequest = {
    queryMyInterview(data){
        return request.post(api.queryMyInterview,data)
    },
    queryMyDelivery(data){
        return request.post(api.queryMyDelivery,data)
    },
    homeQueryWork(data){
        return request.post(api.homeQueryWork,data)
    },
    getIsHaveNotRed(data){
        return request.get(api.getIsHaveNotRed,data)
    },
    queryUserHomeNumber(data){
        return request.get(api.queryUserHomeNumber,data)
    },
    curriculumDetail(data){
        return request.get(api.curriculumDetail,data)
    },
    schoolAll(data){
        return request.get(api.schoolAll,data)
    },
    schoolClass(data){
        return request.get(api.schoolClass,data)
    },
    newsList(data){
        return request.get(api.newsList2,data)
    },
    getHomeNewList(data){
        return request.get(api.getHomeNewList,data)
    },
    curriculumindex(data){
        return request.get(api.curriculumindex,data)
    },
    schoolNewsindex(data){
        return request.get(api.schoolNewsindex,data)
    },
    getLaseByToken(data){
        return request.get(api.getLaseByToken,data)
    },
    forwardtime(data){
        return request.get(api.forwardtime,data)
    },
    forward(data){
        return request.post(api.forward, data)
    },
    collectionResume(data){
        return request.post(api.collectionResume, data)
    },
    postResume(data){
        return request.post(api.postResume, data)
    },
    getOnlineJobFairDetails(data){
        return request.get(api.getOnlineJobFairDetails,data)
    },
    getSchool(data){
        return request.get(api.school,data)
    },
    resetPassword(data){
        return request.post(api.resetPassword, data)
    },
    sendPhoneCodeForPassword(data){
        return request.get(api.sendPhoneCodeForPassword, data)
    },
    resumeAddProject(data) {
        return request.post(api.resumeAddProject, data)
    },
    resumeUpdateProject(data) {
        return request.post(api.resumeUpdateProject, data)
    },
    resumeAddEducation(data) {
        return request.post(api.resumeAddEducation, data)
    },
    resumeUpdateEducation(data) {
        return request.post(api.resumeUpdateEducation, data)
    },
    resumeDeleteSection(data) {
        /*
        type:
        *   1:教育经历页面
            2:作品页面
            3:工作经历
            4:培训经历
            5:项目经历
            6:其他信息
            id:sectionId
        * */
        return request.get(api.resumeDeleteSection, data)
    },
    resumeUpdateWork(data) {
        return request.post(api.resumeUpdateWork, data)
    },
    resumeAddWork(data) {
        return request.post(api.resumeAddWork, data)
    },
    userLogin(data) {
        return request.post(api.userLogin, data)
    },
    userRegister(data) {
        return request.post(api.userRegister, data)
    },
    sendPhoneCode(data) {
        return request.get(api.sendPhoneCode, data)
    },
    forgetPassword(data) {
        return request.post(api.forgetpassword, data)
    },
    setRealName(data) {
        return request.get(api.setRealName, data)
    },
    setUserInfo(data) {
        return request.post(api.setUserInfo, data)
    },
    userLogout() {
        return request.get(api.userLogout)
    },
    getAllIndustry() {
        return request.get(api.getAllIndustry)
    },
    getIndexData(data) {
        return request.post(api.getIndexData, data)
    },
    getJobList(data) {
        return request.post(api.getJobList, data)
    },
    getJobDetail(data) {
        return request.get(api.getJobDetail, data)
    },
    getLngLatByAddress(data) {
        return request.get(api.getLngLatByAddress, data)
    },
    getCompanyList(data) {
        return request.post(api.getCompanyList, data)
    },
    getNatureList() {
        return request.get(api.getNatureList)
    },
    getIndustryList() {
        return request.get(api.getIndustryList)
    },
    getCompanyDetail(data) {
        return request.get(api.getCompanyDetail, data)
    },
    getJobListByCompanyId(data) {
        return request.post(api.getJobListByCompanyId, data)
    },
    getUserResumeList() {
        return request.get(api.getUserResumeList)
    },
    getResumeInfo(data) {
        return request.get(api.getResumeInfo, data)
    },
    getUserJobFairList(data) {
        return request.post(api.getUserJobFairList, data)
    },
    getUserJobFairInfo(data) {
        return request.get(api.getUserJobFairInfo, data)
    },
    setDefaultResume(data) {
        return request.get(api.setDefaultResume, data)
    },
    getWorkList(data) {
        return request.get(api.getWorkList, data)
    },
    getUserInfo(data) {
        return request.get(api.getUserInfo, data)
    },
    createResumeBase(data) {
        return request.post(api.createResumeBase, data)
    },
    deleteResume(data) {
        return request.get(api.deleteResume, data)
    },
    updateResume(data) {
        return request.post(api.updateResume, data)
    },
    uploadResumeUrl(data) {
        return request.get(api.uploadResumeUrl, data)
    },
    getnewsList(data) {
        return request.get(api.newsList, data)
    },
    getindustryClass(data) {
        return request.get(api.industryClass, data)
    },
    getclassificationList(data) {
        return request.get(api.classificationList, data)
    },
    getnewsById(data) {
        return request.get(api.newsById, data)
    },
    sendMessage(data) {
        return request.post(api.sendMessage, data)
    },
    listByPid(data) {
        return request.get(api.listByPid, data)
    },
    messageList(data) {
        return request.get(api.messageList, data)
    },
    orgList(data) {
        return request.get(api.district, data)
    },
    getNewWork(data) {
        return request.get(api.getNewWork, data)
    },

}
export default userRequest