<template>
    <div class="layout" style="height: 100%;">
        <el-container style="height: 100%;">
            <el-aside :width="sidewidth" >
                <Menu :activePath="activePath" :changmenu="isCollapse" :logourl="logo"  />
            </el-aside>
            <el-container>
                <el-header><Head @getchange="changeiscoll"/></el-header>
                <el-main><router-view></router-view></el-main>
            </el-container>
        </el-container>
        
    </div>
</template>
<script>
import Menu from "../Menu.vue"
import Head from "../Head.vue"
export default { 
    data() {
        return{ 
            isCollapse:false,
            sidewidth:"250px",
            logo:require("../../assets/logo.png"),
            activePath:"",
        }
    },
    components:{
        Menu,
        Head
    },
    watch: {
        $route(to, from) {
            this.activePath = to.path
        }
    },
    methods: {
        changeiscoll(data){
            this.isCollapse=data
            if(data){
                this.sidewidth="68px"
                this.logo=require("../../assets/logo2.png")
            }else{
                this.sidewidth="250px"
                this.logo=require("../../assets/logo.png")
            }
        },
        
    }
}
</script>
<style lang="scss">
   @import '../../assets/css/public.css';
el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
.el-header{ } 
.el-aside::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  }
  .el-aside::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
//   box-shadow   : inset 0 0 5px rgba(40, 65, 90, 0.2);
  background   : #28415a;
  }
  .el-aside::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
//   box-shadow   : inset 0 0 5px rgba(40, 65, 90, 0.2);
  border-radius: 5px;
  background   : #ededed;
  }
</style>