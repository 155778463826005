import axios from 'axios'
import { Message } from 'element-ui'

let pedding = []
const CancelToken = axios.CancelToken
let whiteList = [ //无需token的接口数组
    '/sms/sendCode',
    '/sms/checkCode',
    '/sms/sendForGetCode',
    '/memLogin/login',
    '/memLogin/register',
    '/memLogin/logOut',
    '/memLogin/forgetPassword',
    '/app/user/website',
    '/app/work/industry/selectAll?'
    ]

const needEmptyTokenList = [
    '/app/work/queryWork', // 用户端找工作
    '/app/member/queryMember',  // 找企业
    '/app/work/industry/selectAllCompany?',// 找公司
    '/app/work/nature/selectAll?',// 找公司
    '/app/user/website',
    '/app/work/selectWork',
    '/app/member/selectMemberById',
    '/app/work/selectMyWorkPage',
    '/app/work/selectById',

]
//let token = JSON.parse(window.sessionStorage.getItem('token'))
//console.log(7,token)
//let token = null
//创建axios实例
const service = axios.create({
    baseURL: '/api', // 基础地址 要请求的url前缀
    // timeout: 10000, // 请求超时时间
    withCredentials: true,
    headers: {},
})
// request 请求拦截
service.interceptors.request.use((config) => {
    let token = ""
    if(window.localStorage.getItem('token')){  // 确认有值，再JSON
        token = JSON.parse(window.localStorage.getItem('token'))
    }

    let firturl=config.url
    // console.log(7,token)
    if (config.method == 'get') {
        config.url=config.url + '?' + config.data
    }

    if (config.method == 'delete') {
        config.url=config.url + '/' + config.data
    }

    const url = config.url.replace(config.baseURL, '')

    let curorg=JSON.parse(localStorage.getItem("curorg"))
    // if(curorg){
    //     config.headers['orgId'] = curorg.orgId
    // }else{
    //     config.headers['orgId'] = 0
    // }
    config.headers['orgId'] = 3


    //验证接口白名单(无需token)
    if(firturl=="/app/member/selectMemberById" || firturl=="/app/work/selectById"){
        config.headers['AuthorToken'] = ''
        return config;
    }

    if(needEmptyTokenList.some(ml=>url==ml)){  //  需要带空串的接口
        // console.log("进入了needEmptyTokenList",url)
        config.headers['AuthorToken'] = ''
        return config;
    }

    if (whiteList.some(wl => url == wl)) {  // 如果路由白名单里有这个接口url
        // console.log('路由白名单接口',config.url)
        return config
    }
    // if (config.method == 'get') {
    //     config.url=config.url + '?' + config.data
    // }
    //加入token
    config.headers['AuthorToken'] = token
    return config
    }, (error) => {
      Promise.reject(error)
    }
)
//respone 响应拦截
service.interceptors.response.use((response) => {
    let geturl=response.config.url
    let ishave=geturl.indexOf("/sms/sendAppRegSms") != -1
    if((response.config.url==='/memLogin/login' || response.config.url==='/app/userLogin/login')&&response.data.code==200){
        window.localStorage.setItem('token', JSON.stringify(response.data.data.token))
    }
    const data = response.data
    //状态暂为模拟
    if (data.code == 200) {
        return data
    }
    if (data.code == 800) {
        return data
    }
    else if(data.code == 400 && ishave){
        return data
    }
     else if (data.code == 401) {
        // Message.error({
        //     showClose: true,
        //     message:  '登录过期，请先登录',
        //     type: 'error',
        // })
        alert("登录过期，请先登录")
        window.localStorage.setItem('token',"")
        window.localStorage.setItem('userInfo',"")
        //自动跳转登录
        const roleId = JSON.parse(window.localStorage.getItem('roleId'))  // 在每个登录页面进入时注册
        console.log("登录过期获取到的用户id",roleId)
        if (roleId == 1) {
            window.location.href = '/#/newAdminLogin'
        } else if (roleId == 2) {
            window.location.href = '/#/newLogin'
        } else if (roleId == 99) {
            console.log('用户端')
            window.location.href = '/#/newUserLogin'
        } else { // 没有进入过登录页面，跳转到首页
            window.location.href = '/#/'
        }
    } else if(data.code === 500){
        Message.error({
            showClose: true,
            message:  data.message,
            type: 'error',
        })
    }else{
        //抛出异常状态，手动处理
        return Promise.reject(data.message)
    }
}, (error) => {
    // 异常处理
    return Promise.reject(error);
})
export default service
