<template>
    <div class="leftbar">
        <div class="logo flex align-center justify-center" style="height:60px;color:#fff; font-size:24px;" @click="gotoHome">
            <img :src="logourl"  alt="">
        </div>
        <el-menu
            style="border-right:none;"
            :default-active="activePath"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            router
            @close="handleClose"
            :collapse="changmenu"
            background-color="#1f2d3d"
            :unique-opened="true"
            text-color="#bfcbd9"
            active-background-color=""
            active-text-color="#ffffff">
            <el-submenu :index=index.toString() v-for="(item,index) in menuList" :key="index">
                <template slot="title">
                <i :class=item.icon :style="colorlist[index]"></i>
                <span>{{item.name}}</span>
                </template>
                <el-menu-item v-for="(childItem,cindex) in item.child" :key="cindex" :index=childItem.page><u>{{childItem.name}}</u></el-menu-item>
            </el-submenu>
        </el-menu>
    </div>
</template>
<script>
import _api from '@/api/index'
export default {
    data(){
        return{
            url:"/rolePer/getPermission",
            isCollapse:false,
            menuList:[],
            colorlist:[
                "color: rgb(191 191 191)",
                "color: rgb(191 191 191)",
                "color: rgb(191 191 191)",
                "color: rgb(191 191 191)",
                "color: rgb(191 191 191)",
                "color: rgb(191 191 191)",
                "color: rgb(191 191 191)",
            ],
            rulesList:[]
        }
    },
    created(){
        this.getMenuList()
    },
    props:{
        changmenu:{
            type:Boolean,
            default:false
        },
        logourl:{
            type:String
        },
        activePath: {
            type: String,
            default: ''
        }
    },
    methods:{
        gotoHome(){
            this.$router.push("/home")
        },
        getMenu(){
            _api.get(this.apiurl,{}).then(res=>{
                
            })
        },
        handleOpen(key, keyPath) {
        //console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            //console.log(key, keyPath);
        },
        getMenuList(){
            _api.get(this.url,{}
            ).then(res=>{
                if (res.success) {
                    this.menuList = res.data
                    // console.log('menuList', this.menuList)
                }
            }).catch(err =>{
                console.log(err)
                this.$message.error(err)
            })
        },
        getRules(arr) {
            arr.forEach((item, index) => {
                if (item.child.length > 0) {
                    item.child.forEach((item2, index2) => {
                        this.rulesList.push(item2.page)
                    })
                }
            })
            // console.log('rulesList', this.rulesList)
            // window.localStorage.setItem('rulesList', JSON.stringify(this.rulesList))
        }
    },
    watch: {
        // menuList(val) {
        //     this.getRules(val)
        // }
    }
}
</script>
<style scoped>
.leftbar{ background: #28415a; height: 100%;}
.logo{ cursor: pointer;}

</style>
