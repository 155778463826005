<template>
    <div>
     <!--单个图片上传组件-->
        <el-upload
        class="avatar-uploader"
        action="/api/qiniu/getImageUrl"
        :show-file-list="false"
        
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
        <i v-if="noupload || !modeldata.url" class="myupload avatar-uploader-icon" style="border: #e8e8e8 solid 1px;border-radius: 10px; overflow: hidden;" v-loading="loading">+</i>
        <img v-if="modeldata.url && !noupload" :src="modeldata.url" style="border-radius: 10px; overflow: hidden;" class="avatar">
        
        </el-upload>
    </div>
</template>
<script>
export default {
    data() {
      return {
        imageUrl: '',
        loading:false,
        noupload:false,
      };
    },
    props:{
      modeldata:{
        type:Object
      }
    },
    methods: {
      upLoad(){

      },
      handleAvatarSuccess(res, file) {
        console.log(res)
        this.loading=false
        this.noupload=false
        this.imageUrl =res.data.message;
        //el-icon-plus
        this.modeldata.url= this.imageUrl
        this.$emit("changeurl",this.modeldata.url);

      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 6;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式和PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        this.loading=true
        this.noupload=true
        return isJPG && isLt2M;
      },
    
    }
  }
</script>
<style>
.el-loading-spinner{ top: 20%!important;}

</style>
<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
  
.myupload{ width: 150px; height: 150px; display: block; line-height: 150px!important;}
</style>
