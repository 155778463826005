<template>
    <el-menu
        :default-active="activePath"
        class="el-menu-vertical-demo"
        router
        background-color="#383d4a"
        text-color="#fff">
        <el-submenu index="1">
            <template slot="title">
            <i class="el-icon-message-solid"></i>
            <span>招聘会管理</span>
            </template>
            <el-menu-item index="/company/notice" :class="{'menu-active': $route.path === '/company/notice'}">
                <template slot="title">
                    <span>预约招聘会</span>
                </template> 
            </el-menu-item>
            <el-menu-item index="/company/bookingHistory" :class="{'menu-active': $route.path === '/company/bookingHistory'}">
                <template slot="title">
                    <span>招聘会预约历史</span>
                </template> 
            </el-menu-item>
        </el-submenu>
        <el-submenu index="2">
            <template slot="title">
            <i class="el-icon-s-promotion"></i>
            <span>招聘管理</span>
            </template>
            <el-menu-item index="/company/postJob" :class="{'menu-active': $route.path === '/company/postJob'}">
                <template slot="title">
                    <span>发布职位</span>
                </template> 
            </el-menu-item>
            <el-menu-item index="/company/jobManagement" :class="{'menu-active': $route.path === '/company/jobManagement'}">
                <template slot="title">
                    <span>职位管理</span>
                </template> 
            </el-menu-item>
            <!-- <el-menu-item index="1-5">
                <template slot="title">
                    <span>面邀记录</span>
                </template> 
            </el-menu-item> -->
            <el-menu-item index="/company/faceRecord" :class="{'menu-active': $route.path === '/company/faceRecord'}">
                <template slot="title">
                    <span>面邀记录</span>
                </template> 
            </el-menu-item>
            <el-menu-item index="/company/effect" :class="{'menu-active': $route.path === '/company/effect'}">
                <template slot="title">
                    <span>招聘效果</span>
                </template> 
            </el-menu-item>
        </el-submenu>
         <el-submenu index="3">
            <template slot="title">
            <i class="el-icon-s-data"></i>
            <span>简历管理</span>
            </template>
            <el-menu-item index="/company/resumeCenter" :class="{'menu-active': $route.path === '/company/resumeCenter'}">
                <template slot="title">
                    <span>人才库</span>
                </template> 
            </el-menu-item>
            <el-menu-item index="/company/downloadResume" :class="{'menu-active': $route.path === '/company/downloadResume'}">
                <template slot="title">
                    <span>收到的简历</span>
                </template> 
            </el-menu-item>
            <el-menu-item index="/company/collection" :class="{'menu-active': $route.path === '/company/collection'}">
                <template slot="title">
                    <span>收藏的简历</span>
                </template> 
            </el-menu-item>
        </el-submenu>
        <el-submenu index="4">
            <template slot="title">
            <i class="el-icon-s-custom"></i>
            <span>企业管理</span>
            </template>
            <el-menu-item index="/company/mine" :class="{'menu-active': $route.path === '/company/mine'}">
                <template slot="title">
                    <span>企业中心</span>
                </template> 
            </el-menu-item>
            <el-menu-item index="/company/companyInfo" :class="{'menu-active': $route.path === '/company/companyInfo'}">
                <template slot="title">
                    <span>企业信息</span>
                </template> 
            </el-menu-item>
            <el-menu-item index="/company/integralRecord" :class="{'menu-active': $route.path === '/company/integralRecord'}">
                <template slot="title">
                    <span>积分明细</span>
                </template> 
            </el-menu-item>
            <el-menu-item index="/company/settings" :class="{'menu-active': $route.path === '/company/settings'}">
                <template slot="title">
                    <span>账号设置</span>
                </template> 
            </el-menu-item>
        </el-submenu>
        <el-submenu index="5">
            <template slot="title">
            <i class="el-icon-s-comment"></i>
            <span>消息管理</span>
            </template>
            <el-menu-item index="/company/message" :class="{'menu-active': $route.path === '/company/message'}">
                <template slot="title">
                    <span>消息列表</span>
                </template> 
            </el-menu-item>
        </el-submenu>
        <el-submenu index="6">
            <template slot="title">
            <i class="el-icon-upload"></i>
            <span>数据上报</span>
            </template>
            <el-menu-item index="/company/datareport" :class="{'menu-active': $route.path === '/company/datareport'}">
                <template slot="title">
                    <span>数据上报</span>
                </template> 
            </el-menu-item>
        </el-submenu>
    </el-menu>
</template>
<script>
export default {
    props: {
        activePath: {
            type: String,
            default: '/company/resumeCenter'
        }
    }
    
}
</script>
<style lang="scss" scoped>
 @import '../../assets/css/global.scss';
.el-menu-item.is-active{
    color: #fff !important;
    border-left: 4px solid $main;
    background-color: $bgcloor !important;
}
</style>