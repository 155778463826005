<template>
 <div class="loginbigbox">
    <div class="bgbox"></div>

    <div class="container-mid flex align-center justify-center">
        <div class="container-mid-right" >
            <!-- 注册 -->
            <div v-if="isRegister" class="container-mid-right-card">
                <el-form ref="regForm" :rules="rules" :model="regForm">
                    <span class="login-form-title">欢迎注册</span>
                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="用户名" size="large"  v-model="regForm.userName">
                            <template slot="prepend"><i class="el-icon-user-solid" style="font-size:18px;"></i></template>
                        </el-input>
                    </div>
                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="密码" size="large"  v-model="regForm.passWord">
                            <template slot="prepend"><i class="el-icon-lock" style="font-size:18px;"></i></template>
                        </el-input>
                    </div>

                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="手机号" size="large"  v-model="regForm.userPhone">
                            <template slot="prepend"><i class="el-icon-phone" style="font-size:18px;"></i></template>
                            <template slot="append">
                                <el-button
                                    v-preventReClick
                                    v-show="showBtn"
                                    :loading="zhuceLoading"
                                    @click="sendCode"
                                    class="input-btn"
                                    type="info"
                                >发送验证码
                                </el-button
                                >
                                <el-button
                                        v-show="!showBtn"
                                        disabled
                                        class="input-btn"
                                        type="info"
                                >{{ count }} s
                                </el-button
                                >
                            </template>
                        </el-input>
                    </div>
                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="验证码" size="large"   v-model="regForm.verifiCode">
                            <template slot="prepend"><i class="el-icon-key" style="font-size:18px;"></i></template>
                        </el-input>
                    </div>
                    <div>
                        <el-button
                                type="primary"
                                v-preventReClick
                                @click="register('regForm')"
                                class="login-form-btn"
                        >注册
                        </el-button
                        >
                    </div>
                    <div class="login-form-item-tip">
                        <el-link @click="toLogin">返回登录</el-link>
                    </div>
                </el-form>
            </div>

            <!-- 登录 -->
            <div
                    v-else-if="isForget == false && isRegister == false"
                    class="container-mid-right-card"
            >
                <el-form ref="logForm" :rules="rules" :model="logForm">
                    <span class="login-form-title">上饶人力资源-企业登录</span>
                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="请输入登录名" size="large" @keyup.enter.native="logIn('logForm')" v-model="logForm.userPhone">
                            <template slot="prepend"><i class="el-icon-user" style="font-size:18px;"></i></template>
                        </el-input>
                    </div>
                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="请输入密码" size="large" :type="type" @keyup.enter.native="logIn('logForm')" v-model="logForm.passWord">
                            <template slot="prepend"><i class="el-icon-lock" style="font-size:18px;"></i></template>
                            <template slot="append">
                                <i class="el-icon-view cursor-pointer" @click="changeType" style="font-size:18px; color:#ccc;" > </i>
                            </template>
                        </el-input>
                    </div>
                    <div>
                        <el-button
                                type="primary"
                                v-preventReClick
                                @click="logIn('logForm')"
                                class="login-form-btn"
                        >登录
                        </el-button
                        >
                    </div>
                    <div class="login-form-item-tipinfo">
                        <div class="login-form-item-tip">
                            <el-link @click="toFor">忘记密码？</el-link>
                        </div>
                        <div class="login-form-item-tip">
                            <el-link @click="toRes">立即注册</el-link>
                        </div>
                    </div>
                </el-form>
            </div>

            <!-- 找回密码 -->
            <div v-if="isForget" class="container-mid-right-card">
                <el-form ref="modifyForm" :rules="rules" :model="modifyForm">
                    <span class="login-form-title">重置密码</span>
                            
                            <div class="formitem margin-bottom-sm">
                                <el-input placeholder="手机号" size="large"  v-model="modifyForm.userPhone">
                                    <template slot="prepend"><i class="el-icon-phone" style="font-size:18px;"></i></template>
                                    <template slot="append">
                                        <el-button
                                                v-preventReClick
                                                v-show="showBtn1"
                                                @click="sendCode1"
                                                :loading="wangjiLoading"
                                                class="input-btn"
                                                type="info"
                                        >发送验证码
                                        </el-button
                                        >
                                        <el-button
                                                v-show="!showBtn1"
                                                disabled
                                                class="input-btn"
                                                type="primary"
                                        >{{ count1 }} s
                                        </el-button
                                        >
                                    </template>
                                </el-input>
                            </div>

                        <div class="formitem margin-bottom-sm">
                            <el-input placeholder="验证码" size="large"   v-model="modifyForm.verifiCode">
                                <template slot="prepend"><i class="el-icon-key" style="font-size:18px;"></i></template>
                            </el-input>
                        </div>

                    <div class="formitem margin-bottom-sm">
                        <el-input placeholder="新密码" size="large"   v-model="modifyForm.passWord">
                            <template slot="prepend"><i class="el-icon-lock" style="font-size:18px;"></i></template>
                        </el-input>
                    </div>
                    <div>
                        <el-button
                                type="primary"
                                class="login-form-btn"
                                v-preventReClick
                                @click="modifySubmit('modifyForm')"
                        >确定
                        </el-button
                        >
                    </div>
                    <div class="login-form-item-tip">
                        <el-link @click="toLogin">返回登录</el-link>
                    </div>
                </el-form>
            </div>
        </div>
    </div>

 </div>
</template>
<script>
    import _api from "@/api/index";
    import apiUrl from "@/api/public";

    let checkMobile = (rule, value, cb) => {
        const regMobile = /^1[0-9]{10}$/

        if (regMobile.test(value)) {
            return cb()
        }
        cb(new Error('请输入合法的手机号'))
    }
    let checkpassword = (rule, value, cb) => {
        // const regPassword = /^[a-z0-9]{6,16}$/
        const regPassword = /^[0-9a-zA-Z]{6,12}$/g
        if (regPassword.test(value)) {
            return cb()
        }
        cb(new Error('密码只能由6-12位字母或数字组成'))
    }
    export default {
        components: {},
        created() {
            window.localStorage.setItem("roleId", JSON.stringify(2));

            

        },
        data() {
            return {
                type:"password",
                bgImg: require("../../assets/loginBG.jpg"),
                isRegister: false,
                isForget: false,
                logForm: {
                    userPhone: "",
                    passWord: "",
                },
                regForm: {
                    userName: null,
                    passWord: "",
                    userPhone: null,
                    verifiCode: "",
                },
                modifyForm: {
                    userPhone: "",
                    passWord: "",
                    verifiCode: "",
                },
                rules: {
                    userName: [
                        {required: true, message: "请输入用户名", trigger: "blur"},
                    ],
                    userPhone: [
                        {required: true, message: "请输入手机号", trigger: "blur"}, {validator: checkMobile, trigger: 'blur'}
                    ],
                    passWord: [{required: true, message: "请输入密码", trigger: "blur"}, {
                        validator: checkpassword,
                        trigger: 'blur'
                    }],
                    verifiCode: [
                        {required: true, message: "请输入验证码", trigger: "blur"},
                    ],
                },
                showBtn: true,
                count: "",
                timer: null,
                showBtn1: true,
                count1: "",
                timer1: null,
                companyWhiteList: [
                    "/",
                    "/newLogin",
                    '/newUserLogin',
                    "/newAdminLogin",
                    "/company/postJob",
                    "/company/certification",
                    "/company/resumeCenter",
                    "/company/notice",
                    "/company/bookingHistory",
                    "/company/orderBooth",
                    "/company/downloadResume",
                    "/company/collection",
                    "/company/resumeInfo",
                    "/company/jobManagement",
                    "/company/mine",
                    "/company/certification",
                    "/company/companyInfo",
                    "/company/settings",
                    '/company/integralRecord',
                    '/company/effect',
                    '/company/faceRecord',
                    '/company/blank'
                ],
                zhuceLoading: false,
                wangjiLoading: false
            }
        },
        methods: {
            changeType(){
                if(this.type){
                    this.type=''
                }else{
                    this.type="password"
                }
            },
            getCode() {
                const TIME_COUNT = 60;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.showBtn = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.showBtn = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            },
            getCode1() {
                const TIME_COUNT = 60;
                if (!this.timer1) {
                    this.count1 = TIME_COUNT;
                    this.showBtn1 = false;
                    this.timer1 = setInterval(() => {
                        if (this.count1 > 0 && this.count1 <= TIME_COUNT) {
                            this.count1--;
                        } else {
                            this.showBtn1 = true;
                            clearInterval(this.timer1);
                            this.timer1 = null;
                        }
                    }, 1000);
                }
            },
            signIn() {
                this.isForget = false;
                this.isRegister = false;
                this.clear(0);
            },
            logIn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let that = this;
                        let {userPhone, passWord} = this.logForm;
                        _api.post(apiUrl.login, {
                                phone: userPhone,
                                password: passWord,
                                loginType: 2,
                            }).then((res) => {
                                if (res.success) {
                                    this.$message.success("登录成功");
                                    window.localStorage.setItem(
                                        "userInfo",
                                        JSON.stringify(res.data)
                                    );
                                    window.localStorage.setItem(
                                        "authInfoStatus",
                                        String(res.data.authInfoStatus)
                                    );
                                    window.localStorage.setItem(
                                        "memberBaseInfoId",
                                        res.data.memberBaseInfoId
                                    );
                                    this.$store.commit("changeUserManager", {
                                        userManagerState: 2,
                                    });
                                    // window.localStorage.setItem(
                                    //   "rulesList",
                                    //   JSON.stringify(this.companyWhiteList)
                                    // );
                                    window.localStorage.setItem("currentClient", 'company')
                                    window.localStorage.setItem("hasLogin", 'true')  // 登录标记
                                    window.localStorage.setItem("curorg", null)  // 清除已经选择的分站信息
                                    setTimeout(() => {
                                        this.$router.push({
                                          path: "/company/mine",
                                          query:{
                                            needAuthCheck:true
                                          }
                                        }).catch(() => {
                                        });
                                    }, 1500);
                                }
                            }).catch((err) => {
                                this.$message.error(err);
                            });
                    } else {
                        this.$message.error("信息未填写完整，请检查！");
                        return false;
                    }
                });
            },
            sendCode() {
                
                this.zhuceLoading = true
                let {userPhone, verifiCode} = this.regForm;
                    console.log(userPhone)
                    if(!(/^1[3456789]\d{9}$/.test(userPhone))){
                        this.$message.error("手机号错误！")
                        this.zhuceLoading = false
                        return false; 
                    }else{
                         _api
                            .get(apiUrl.sendCode, {
                                phone: userPhone,
                            })
                            .then((res) => {
                                if (res.success) {
                                    this.zhuceLoading = false
                                    this.$message.success("验证码已发送");
                                    this.getCode();
                                }
                            })
                            .catch((err) => {
                                this.zhuceLoading = false
                                this.$message.error(err);
                                this.$message.error(err)
                            });
                    }


            },
            register(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let {userName, passWord, userPhone, verifiCode} = this.regForm;
                        _api
                            .post(apiUrl.checkCode, {
                                phone: userPhone,
                                code: verifiCode,
                                type: 1,
                            })
                            .then((res) => {
                                if (res.success) {
                                    _api
                                        .post(apiUrl.register, {
                                            userName: userName,
                                            password: passWord,
                                            phone: userPhone,
                                            code: verifiCode,
                                        })
                                        .then((res) => {
                                            if (res.success) {
                                                this.$message.success("注册成功");
                                                this.signIn();
                                            }
                                        }).catch((err) => {
                                        this.$message.error(err);
                                    });
                                }
                            })
                            .catch((err) => {
                                this.$message.error(err);
                            });
                    } else {
                        this.$message.error("信息未填写完整，请检查！");
                        return false;
                    }
                });
            },
            forget() {
                this.isForget = !this.isForget;
            },
            back() {
                this.isForget = false;
            },
            sendCode1() {
                this.wangjiLoading = true
                let userPhone = this.modifyForm.userPhone;
                console.log(userPhone)
                // this.$refs['modifyForm'].validateField('userPhone', (phoneError) => {
                    
                    if(userPhone){
                        
                        if(!(/^1[23456789]\d{9}$/.test(userPhone))){
                            this.$message.warning("手机号码有误，请重填！")
                            // this.form.phone=null
                            return;
                        }else{
                            _api
                            .get(apiUrl.sendForGetCode, {
                                phone: userPhone,
                            })
                            .then((res) => {
                                if (res.success) {
                                    this.wangjiLoading = false
                                    this.$message.success("验证码已发送");
                                    this.getCode1();
                                }
                            })
                            .catch((err) => {
                                this.wangjiLoading = false
                                this.$message.error(err);
                                console.log(err);
                            });
                        }

                    }else{
                        this.$message.error("请输入手机号码！");
                        this.wangjiLoading = false
                    }

                    

                        
                    
                // })
            },
            modifySubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let {passWord, userPhone, verifiCode} = this.modifyForm;
                        _api
                            .post(apiUrl.checkCode, {
                                phone: userPhone,
                                code: verifiCode,
                                type: 2,
                            })
                            .then((res) => {
                                if (res.success) {
                                    setTimeout(() => {
                                    }, 1000);
                                }
                            })
                            .then(
                                _api
                                    .post(apiUrl.forgetPassword, {
                                        password: passWord,
                                        phone: userPhone,
                                        code: verifiCode,
                                    })
                                    .then((res) => {
                                        if (res.success) {
                                            this.$message.success("修改成功");
                                            setTimeout(() => {
                                                this.isForget = false;
                                            }, 1000);
                                        }
                                    })
                                    .catch((err) => {
                                        this.$message.error(err);
                                    })
                            )
                            .catch((err) => {
                            });
                    } else {
                        this.$message.error("信息未填写完整，请检查！");
                        return false;
                    }
                });
            },
            toLogin() {
                this.isRegister = false;
                this.isForget = false
                this.$nextTick(() => {
                    // debugger
                    this.$refs.logForm.clearValidate()
                })
            },
            toRes() {
                this.isRegister = true;
                this.isForget = false
                this.$nextTick(() => {
                    // debugger
                    this.$refs.regForm.clearValidate()
                })
            },
            toFor() {
                this.isRegister = false;
                this.isForget = true
                this.$nextTick(() => {
                    // debugger
                    this.$refs.modifyForm.clearValidate()
                })
            },
            clear(num) {
                if (num == 1) {
                    this.regForm.userName = "";
                    this.regForm.passWord = "";
                    this.regForm.userPhone = "";
                    this.regForm.verifiCode = "";
                } else {
                    this.logForm.userPhone = "";
                    this.logForm.passWord = "";
                    this.modifyForm.userPhone = "";
                    this.modifyForm.passWord = "";
                    this.modifyForm.verifiCode = "";
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
.loginbigbox{ position: relative;width: 100%; height: 100vh;}
.bgbox{position: absolute;width: 100%; opacity: 0.7; height: 100vh;background: url("https://hpk2.quxingcm.com/qyloginbg2.jpg") no-repeat; background-size: cover; z-index: 1;}
    * {
        box-sizing: border-box;
    }

    html,

    .new-login {
        height: 100%;
    }

    .container-top {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20%;
        background-color: #333333;

        .container-top-text {
            font-size: 55px;
            font-family: PingFang SC;
            font-weight: 800;
            color: white;
            margin-right: 20px;
        }

        .container-top-text2 {
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #cccccc;
        }
    }

    .container-mid {
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2000;
        

        .container-mid-left {
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .container-mid-right {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .container-mid-right-card {
                width: 600px;
                height: auto;
                padding-bottom: 40px;
                background-color: white;
                border-radius: 10px;
                -webkit-box-shadow: rgba(0,0,0,0.05) 0px 0px 20px;
                -moz-box-shadow: rgba(0,0,0,0.05) 0px 0px 20px;
                box-shadow: rgba(0,0,0,0.05) 0px 0px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .login-form-title {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #050505;
        margin-bottom: 40px;
    }

    .login-form-item {
        width: 450px;
        height: 50px;
        display: flex;
        border-bottom: 1px solid #cccccc;
        padding-bottom: 10px;
        margin-top: 30px;

        .input-icon {
            width: 50px;
            font-size: 35px;
            border-right: 1px solid #cccccc;
        }

        .input-content ::v-deep {
            width: 100%;

            .el-input__inner {
                border: 0;
                border-radius: 0px;
            }
        }

        .input-content-phone ::v-deep {
            width: 300px;

            .el-input__inner {
                border: 0;
                border-radius: 0px;
            }
        }

        .input-btn {
            width: 100px;
            padding: 0;
        }
    }

    .login-form-btn {
        width: 450px;
        margin-top: 10px;
    }

    .login-form-item-tipinfo {
        width: 450px;
        display: flex;
        justify-content: space-between;
    }

    .login-form-item-tip {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    .container-btm {
        width: 100%;
        height: 10%;
        background-color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #cccccc;
    }
</style>