const companyList = [
    "/",
    "/newLogin",
    '/newUserLogin',
    "/newAdminLogin",
    "/company/postJob",
    "/company/certification",
    "/company/resumeCenter",
    "/company/notice",
    "/company/bookingHistory",
    "/company/orderBooth",
    "/company/downloadResume",
    "/company/collection",
    "/company/resumeInfo",
    "/company/jobManagement",
    "/company/mine",
    "/company/certification",
    "/company/companyInfo",
    "/company/settings",
    '/company/integralRecord',
    '/company/effect',
    '/company/faceRecord',
    '/company/blank',
    '/company/video',
    '/company/videohome',
    '/company/video-call',
    '/company/message',
    '/company/messageshow',
    '/company/datareport',
    '/company/datalist',
    '/user/tempInterview',
    '/user/tempIndex',
    '/user/newJobList',
    '/user/companyJobs',
]

export default {companyList}
