import request from "@/util/request.js";
import qs from 'query-string';

export default{
    get(url,params){
        return request({
            url: url,
            method: 'get',
            data: qs.stringify(params),
        });
    },
    delete(url,params){
        return request({
            url: url,
            method: 'delete',
            data: params,
        });
    },
    post(url,params){
        return request({
            url: url,
            method: 'post',
            data:params,
        });
    },
    put(url,params){
        return request({
            url: url,
            method: 'put',
            data:params,
        });
    },
    download(url,params){
        return request({
            url: url,
            method: 'post',
            responseType: 'blob',
            data: params,
        });
    },
}


