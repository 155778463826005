<template>
    <el-header>
        <div class="logo-img" @click="$router.push('/user/tempIndex')">
            <img src="../../assets/img/company/sr_logo.png"/>
        </div>
        <div class="search">
            <!-- <el-input
                placeholder="请输你要招的职位名称，如 “销售"
                prefix-icon="el-icon-search"
                size="small"
                style="width:400px;"
                v-model="search">
            </el-input> -->
            <input v-model="searchJobName" @keyup.enter="routeToResumeCenter" placeholder="请输你要招的职位名称，如 销售"/>
            <div class="active button4" @click="routeToResumeCenter">搜索</div>
            <!-- <div class="active">搜 索</div> -->
            <!-- <el-button size="small active">搜 索</el-button> -->
            <!-- <el-input placeholder="请输你要招的职位名称，如 销售" v-model="search" class="input-with-select" size="small" style="width:500px">
                <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input> -->
            <div class="open-job" @click="routeToPostJob">
                <img src="../../assets/img/company/cxnomark.gif" alt="">
            </div>
        </div>
        <!-- 头部下拉 -->
        <div class="drop">
            <el-dropdown>
                <span class="el-dropdown-link">
                    <div class="avata">
                        <img src="../../assets/img/company/avata.png" alt="">
                        <span class="company-name text-overflow">{{userInfo.userName}}</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <div @click="routeToMine">
                        <el-dropdown-item><i class="el-icon-s-home"></i> 企业中心</el-dropdown-item>
                    </div>
                    <div @click="routeToSettings">
                        <el-dropdown-item><i class="el-icon-s-tools"></i> 账号设置</el-dropdown-item>
                    </div>
                    <div @click="logOut">
                        <el-dropdown-item><i class="el-icon-message-solid"></i>退出登录</el-dropdown-item>
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </el-header>
</template>
<script>
    import _api from '@/api/index'
    import apiUrl from '@/api/public'
    import {mapState} from 'vuex'

    export default {
        props: {
            componyName: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                searchJobName: '',
                saveFlag: true,
                userInfo: {}
            }
        },
        created() {
            let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
            this.userInfo = userInfo
        },
        computed: {
            ...mapState(['searchJob'])
        },
        watch: {
            searchJob(val) {
                this.searchJobName = val
            }
        },
        methods: {
            logOut() {
                _api.get(apiUrl.logOut, {}).then(res => {
                    if (res.code === 200) {
                        this.$message.success('退出登录成功')
                        window.localStorage.setItem('userInfo', JSON.stringify(""))
                        window.localStorage.setItem('token', JSON.stringify(""))
                        window.localStorage.setItem('authInfoStatus', null)     // 企业的认证信息
                        this.$router.push({path: '/newLogin'})
                    }
                }).catch(err => {
                    this.$message.warning('退出登录失败，请稍后重试')
                    // this.$router.push({path:'/newLogin'})
                })
            },
            routeToMine() {
                this.$router.push({
                    path: '/company/mine'
                }).catch(() => {
                })
            },
            routeToSettings() {
                this.$router.push({
                    path: '/company/settings'
                }).catch(() => {
                })
            },
            routeToPostJob() {
                if (this.$route.path === '/company/postJob') {
                    this.$router.push({
                        path: '/company/blank',
                    }).catch(() => {
                    })
                } else {
                    this.$router.push({
                        path: '/company/postJob',
                    }).catch(() => {
                    })
                }
            },
            routeToResumeCenter() {
                if (this.searchJobName.trim() === '') {
                    this.$message.error('查询条件不能为空')
                    return
                }
                // console.log('searchJobName', this.searchJobName)
                let searchJob = this.searchJobName
                window.localStorage.setItem('searchJob', searchJob)
                // if (this.saveFlag) {
                //     window.localStorage.setItem('searchJob', searchJob)
                // }
                // this.saveFlag = false
                this.$store.commit('changeSearchJob', {searchJob})
                this.$router.push({
                    path: '/company/resumeCenter',
                }).catch(() => {
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../assets/css/global.scss';

    .el-input.is-active .el-input__inner, .el-input__inner:focus {
        border: none;
    }

    .el-dropdown-menu__item {
        font-size: 13px !important;
        color: #666;
        min-width: 100px;
    }

    .el-header {
        display: flex;
        height: 84px !important;
        align-items: center;
    }

    .logo-img {
        width: 120px;
        margin-right: 30px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .search {
        display: flex;

        ::v-deep .el-button {
            margin-left: 10px;
            color: #fff;
            background: $main;
            border-radius: 0;

            &:active {
                border: none;
            }

            &:hover {
                background: $hover;
            }
        }

        input {
            display: block;
            width: 300px;
            height: 30px;
            padding: 0 12px;
            border: 1px solid #eee;
            outline: none;
        }

        .button4 {
            width: 60px;
            height: 32px;
            line-height: 34px;
            cursor: pointer;
            text-align: center;
            user-select: none;
        }

        .el-input__inner {
            border-radius: 0 !important;
        }

        .el-button {
            margin-left: -1px !important;
        }

        .el-input {
            width: 300px;
            border-radius: 0px;
        }

        .open-job {
            height: 32px;
            cursor: pointer;
            margin-left: 30px;

            img {
                height: 100%;
            }
        }

        div {
            height: 32px;
            line-height: 32px;
            padding: 0 10px;
        }
    }

    .drop {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        .avata {
            display: flex;
            align-items: center;

            img {
                width: 32px;
            }

            .company-name {
                margin-left: 10px;
                font-weight: 550;
                max-width: 100px;
                color: #000;
            }

            .el-icon-arrow-down {
                font-weight: bold;
                color: #000;
            }

        }

    }
</style>
