<template>
    <!-- 分页 -->
    <div class="pagination">
        <el-pagination
            v-if="pageShow"
            hide-on-single-page	
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :small="false"
            :current-page="current"
            :page-sizes="sizeList"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    props: {
        pageShow: {
            type: Boolean,
            default: true
        },
        total: {
            type: Number,
            default: 1000
        },
        current: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        },
        sizeList: {
            type: Array,
            default: () => {
                return [10, 20, 30, 40]
            }
        },

    },
    watch: {
       current(val) {
        //    console.log('页码变化', val)
       } 
    },
    methods: {
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.$emit('handleSizeChange', val)
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`)
            this.$emit('handleCurrentChange', val)
        }
    }
}
</script>
<style lang="scss" scoped>
    .pagination{
        margin: 20px;
        display: flex;
        justify-content: flex-end;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active{
        color: #fff !important;
    }
</style>
<style lang="scss">
.pagination{
    li{
        &.active{
            color: #fff !important;
        }
    }
}
</style>